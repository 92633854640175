#contact-section {
  padding-bottom: 50px;
  padding-top: 50px;
  background: #eaecf1;
  @include breakpoint(m) {
    padding-bottom: 80px;
    padding-top: 80px;
  }
  .title {
    font-weight: bold;
    font-size: 48px;
    color: #2d3047;
    line-height: 72px;
  }
  .description {
    font-size: 18px;
    color: #2d3047;
    letter-spacing: 0;
    line-height: 27px;
    li {
      list-style-position: outside;
      padding-bottom: 15px;
    }
  }
  .email-footer {
    font-weight: bold;
    font-size: 24px;
    color: $red;
    text-decoration: none;
    display: block;
    line-height: 32px;
    padding-bottom: 25px;
    transition: color 300ms ease;
    &:hover {
      color: lighten($red, 7%);
      transition: color 300ms ease;
    }
  }
  .phone-footer {
    font-weight: bold;
    font-size: 24px;
    color: $red;
    text-decoration: none;
    display: block;
    line-height: 32px;
    padding-bottom: 25px;
    transition: color 300ms ease;
    &:hover {
      color: lighten($red, 7%);
      transition: color 300ms ease;
    }
  }
  .description {
    font-size: 18px;
    color: #2d3047;
    letter-spacing: 0;
    line-height: 27px;
  }
}
