.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 0 auto 20px;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid #fff;
  border-right: 1.1em solid #fff;
  border-bottom: 1.1em solid #fff;
  border-left: 1.1em solid #6761fc;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loading-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #ffffff91;
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  p {
    text-align: center;
    font-size: 26px;
    margin: 0;
  }
}

.loading-wrapper-initial {
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
  height: 100vh;
  p {
    text-align: center;
    font-size: 26px;
    margin: 0;
    font-weight: bold;
  }
}
