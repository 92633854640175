@import url("https://fonts.googleapis.com/css?family=Titillium+Web:300,400,600,700");
.white-text {
  color: white; }

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

html,
button,
input,
select,
textarea {
  font-family: inherit; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block; }

body,
form,
fieldset,
legend,
input,
select,
textarea,
button {
  margin: 0; }

audio:not([controls]) {
  display: none;
  height: 0; }

audio,
canvas,
progress,
video {
  display: inline-block; }

progress {
  vertical-align: baseline; }

[hidden],
template {
  display: none; }

img {
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

body {
  font-family: "Titillium Web", sans-serif;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 24px;
  line-height: 1.5rem;
  color: #000;
  font-weight: 400;
  background: #fff; }

p {
  margin: 0 0 20px 0; }

a {
  color: #000;
  text-decoration: underline;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:active, a:hover {
    color: #000;
    outline-width: 0;
    text-decoration: none; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Titillium Web", sans-serif;
  margin: 0; }

h1,
.fs-1 {
  font-size: 32px;
  font-size: 2rem;
  line-height: 38px;
  line-height: 2.375rem; }

h2,
.fs-2 {
  font-size: 26px;
  font-size: 1.625rem;
  line-height: 32px;
  line-height: 2rem; }

h3,
.fs-3 {
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 28px;
  line-height: 1.75rem; }

h4,
.fs-4 {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 24px;
  line-height: 1.5rem; }

h5,
.fs-5 {
  font-size: 16px;
  font-size: 1rem;
  line-height: 22px;
  line-height: 1.375rem; }

h6,
.fs-6 {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 20px;
  line-height: 1.25rem; }

h1 {
  margin-bottom: 0.5em;
  color: #000;
  font-weight: 400; }

h2 {
  margin-bottom: 0.2em;
  color: #000;
  font-weight: 400; }

h3 {
  margin-bottom: 0.2em;
  color: #000;
  font-weight: 400; }

h4 {
  margin-bottom: 0.2em;
  color: #000;
  font-weight: 400; }

h5 {
  margin-bottom: 0.1em;
  color: #000;
  font-weight: 400; }

h6 {
  margin-bottom: 0.1em;
  color: #000;
  font-weight: 400; }

b,
strong,
.strong {
  font-weight: 700; }

em,
.em {
  font-style: italic; }

abbr[title],
.abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

dfn {
  font-style: italic; }

small,
.small {
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 16px;
  line-height: 1rem; }

mark,
.mark {
  background-color: #ff0;
  color: #000; }

sub,
.sub,
sup,
.sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub,
.sub {
  bottom: -0.25em; }

sup,
.sup {
  top: -0.5em; }

del,
.del {
  text-decoration: line-through; }

figure {
  margin: 1em 40px; }

hr,
.hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 1px;
  background: #eee;
  border: 0;
  margin-top: 20px;
  margin-bottom: 20px; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

label,
button,
input,
optgroup,
select,
textarea {
  border-radius: 0;
  color: #000;
  font: inherit;
  margin: 0; }

[type="text"],
[type="email"],
[type="password"],
[type="tel"],
[type="number"],
[type="date"] {
  padding: 10px 10px;
  background-color: #fff;
  border: 0;
  font-size: 18px;
  color: #2d3047;
  letter-spacing: 0;
  line-height: 31.5px;
  -moz-appearance: textfield;
  border-radius: 0;
  width: 100%; }
  @media only screen and (min-width: 740px) {
    [type="text"],
    [type="email"],
    [type="password"],
    [type="tel"],
    [type="number"],
    [type="date"] {
      padding: 25px 29px;
      font-weight: bold;
      font-size: 22px; } }
  [type="text"]:focus,
  [type="email"]:focus,
  [type="password"]:focus,
  [type="tel"]:focus,
  [type="number"]:focus,
  [type="date"]:focus {
    background-color: #fff;
    border-color: #ff4547;
    outline: 0; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="date"]::-webkit-inner-spin-button {
  display: none; }

[type="checkbox"],
[type="radio"] {
  border-radius: 0;
  box-sizing: border-box;
  padding: 0; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

textarea {
  height: 200px;
  border-radius: 0;
  padding: 10px;
  background-color: #fff;
  border: 0;
  overflow: auto;
  border-radius: 0;
  width: 100%;
  font-size: 18px;
  color: #2d3047;
  letter-spacing: 0;
  line-height: 31.5px; }
  textarea:focus {
    background-color: #fff;
    border-color: #ff4547;
    outline: 0; }
  @media only screen and (min-width: 740px) {
    textarea {
      padding: 25px 29px;
      font-weight: bold;
      font-size: 22px;
      height: 300px; } }

.checker .wpcf7-list-item-label {
  display: none; }

.checker .inline {
  font-size: 14px;
  padding-left: 10px; }

select {
  border-radius: 0;
  height: 50px;
  text-transform: none;
  padding: 14px 14px;
  background-color: #fff;
  border: 1px solid #ccc;
  width: 100%; }
  select:focus {
    background-color: #fff;
    border-color: #ff4547;
    outline: 0; }

optgroup {
  font-weight: 700; }

button {
  border-radius: 0;
  overflow: visible;
  text-transform: none;
  cursor: pointer; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  border-radius: 0; }

.wpcf7-submit {
  background: transparent;
  border: 0;
  color: white;
  width: 100%; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

button[disabled],
html input[disabled] {
  cursor: not-allowed; }

input::-webkit-input-placeholder {
  color: #d8d8d8; }

input:-moz-placeholder {
  color: #d8d8d8; }

input::-moz-placeholder {
  color: #d8d8d8; }

input:-ms-input-placeholder {
  color: #d8d8d8; }

body div.wpcf7-validation-errors {
  background: #ee4751;
  color: white;
  text-align: center;
  padding: 10px;
  font-weight: bold;
  border: 0;
  margin-left: 0px;
  width: 100%; }

.screen-reader-response[role="alert"] {
  display: none; }

[role="alert"] {
  display: block;
  padding: 10px;
  margin: 10px 20px 20px;
  background: #ee4751;
  color: white;
  text-align: center;
  font-weight: bold;
  clear: both; }
  [role="alert"].wpcf7-not-valid-tip {
    margin: 0;
    text-align: left;
    font-weight: normal;
    background: transparent;
    padding: 5px 0 0 0; }

body #download-form div.wpcf7-validation-errors {
  background: #ee4751;
  color: white;
  text-align: center;
  padding: 10px;
  font-weight: bold;
  border: 0;
  margin-left: 15px;
  width: auto;
  margin-right: 15px;
  margin-bottom: 20px; }

body .wpcf7-not-valid-tip {
  color: white; }

input::placeholder {
  color: #2a2d34; }

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #2a2d34; }

::-moz-placeholder {
  /* Firefox 19+ */
  color: #2a2d34; }

:-ms-input-placeholder {
  /* IE 10+ */
  color: #2a2d34; }

:-moz-placeholder {
  /* Firefox 18- */
  color: #2a2d34; }

.send-button {
  width: 100%;
  background: transparent;
  border: solid 4px #ff4547;
  padding: 15px;
  cursor: pointer;
  font-weight: bold;
  font-size: 24px;
  color: #ff4547;
  text-align: center;
  transition: background 300ms ease, color 300ms ease; }
  .send-button:hover {
    background: #ff4547;
    transition: background 300ms ease, color 300ms ease;
    color: #fff;
    border-color: #fff; }

.error-message {
  color: #ff4547;
  padding-left: 5px;
  padding-top: 5px;
  font-style: italic; }

.thankyou_message {
  text-align: center; }
  .thankyou_message p.maintext {
    font-weight: bold;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    color: #2d3047;
    line-height: 57px;
    text-transform: uppercase; }
  .thankyou_message p.smaller-text {
    color: #6761fc;
    font-weight: bold; }

.button {
  cursor: pointer;
  border: 1px solid #d7d7d7;
  background-color: #f3f3f3;
  line-height: normal;
  padding: 10px 20px;
  text-decoration: none;
  color: #363636;
  display: inline-block;
  transition: all 0.3s; }
  .button:hover, .button:active {
    text-decoration: none; }
  .button:hover {
    background: #f9f9f9; }

.button-link {
  color: #000;
  text-decoration: underline;
  border: 0;
  background: transparent;
  padding: 0; }
  .button-link:hover {
    text-decoration: none; }
  .button-link:active {
    outline: 0; }

.red-button {
  font-weight: bold;
  font-size: 30px;
  width: 280px;
  height: 61px;
  text-align: center;
  border: 4px solid #ff4547;
  text-decoration: none;
  line-height: 46px;
  display: inline-block;
  position: relative;
  overflow: hidden;
  transition: 300ms border ease; }
  .red-button span {
    position: relative;
    z-index: 2;
    color: #ff4547;
    transition: color 300ms ease; }
  .red-button:after {
    content: "";
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: #ff4547;
    transform: translate(-100%, 0);
    transition: transform 300ms ease; }
  .red-button:hover {
    transition: 300ms border ease;
    border: 4px solid #fff; }
    .red-button:hover span {
      color: #fff;
      transition: color 300ms ease; }
    .red-button:hover:after {
      transition: transform 300ms ease;
      transform: translate(100%, 0); }

.clear:before,
.clear:after {
  content: " ";
  display: table; }

.clear:after {
  clear: both; }

.row:before,
.row:after {
  content: "";
  display: table; }

.row:after {
  clear: both; }

.row {
  position: relative;
  margin-left: -15px;
  margin-right: -15px; }

@media only screen and (min-width: 740px) {
  .row-m {
    position: relative;
    margin-left: -15px;
    margin-right: -15px; }
    .row-m:before, .row-m:after {
      content: "";
      display: table; }
    .row-m:after {
      clear: both; }
  .clear-m:before,
  .clear-m:after {
    content: "";
    display: table; }
  .clear-m:after {
    clear: both; } }

@media only screen and (min-width: 980px) {
  .row-l {
    position: relative;
    margin-left: -15px;
    margin-right: -15px; }
    .row-l:before, .row-l:after {
      content: "";
      display: table; }
    .row-l:after {
      clear: both; }
  .clear-l:before,
  .clear-l:after {
    content: "";
    display: table; }
  .clear-l:after {
    clear: both; } }

@media only screen and (min-width: 1140px) {
  .row-xl {
    position: relative;
    margin-left: -15px;
    margin-right: -15px; }
    .row-xl:before, .row-xl:after {
      content: "";
      display: table; }
    .row-xl:after {
      clear: both; }
  .clear-xl:before,
  .clear-xl:after {
    content: "";
    display: table; }
  .clear-xl:after {
    clear: both; } }

.container {
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto; }

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  float: left; }

.col-1 {
  width: 8.33333%; }

.col-2 {
  width: 16.66667%; }

.col-3 {
  width: 25%; }

.col-4 {
  width: 33.33333%; }

.col-5 {
  width: 41.66667%; }

.col-6 {
  width: 50%; }

.col-7 {
  width: 58.33333%; }

.col-8 {
  width: 66.66667%; }

.col-9 {
  width: 75%; }

.col-10 {
  width: 83.33333%; }

.col-11 {
  width: 91.66667%; }

.col-12 {
  width: 100%; }

@media only screen and (min-width: 740px) {
  .col-1-m,
  .col-2-m,
  .col-3-m,
  .col-4-m,
  .col-5-m,
  .col-6-m,
  .col-7-m,
  .col-8-m,
  .col-9-m,
  .col-10-m,
  .col-11-m,
  .col-12-m {
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    float: left; }
  .col-1-m {
    width: 8.33333%; }
  .col-2-m {
    width: 16.66667%; }
  .col-3-m {
    width: 25%; }
  .col-4-m {
    width: 33.33333%; }
  .col-5-m {
    width: 41.66667%; }
  .col-6-m {
    width: 50%; }
  .col-7-m {
    width: 58.33333%; }
  .col-8-m {
    width: 66.66667%; }
  .col-9-m {
    width: 75%; }
  .col-10-m {
    width: 83.33333%; }
  .col-11-m {
    width: 91.66667%; }
  .col-12-m {
    width: 100%; } }

@media only screen and (min-width: 980px) {
  .col-1-l,
  .col-2-l,
  .col-3-l,
  .col-4-l,
  .col-5-l,
  .col-6-l,
  .col-7-l,
  .col-8-l,
  .col-9-l,
  .col-10-l,
  .col-11-l,
  .col-12-l {
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    float: left; }
  .col-1-l {
    width: 8.33333%; }
  .col-2-l {
    width: 16.66667%; }
  .col-3-l {
    width: 25%; }
  .col-4-l {
    width: 33.33333%; }
  .col-5-l {
    width: 41.66667%; }
  .col-6-l {
    width: 50%; }
  .col-7-l {
    width: 58.33333%; }
  .col-8-l {
    width: 66.66667%; }
  .col-9-l {
    width: 75%; }
  .col-10-l {
    width: 83.33333%; }
  .col-11-l {
    width: 91.66667%; }
  .col-12-l {
    width: 100%; } }

@media only screen and (min-width: 1140px) {
  .col-1-xl,
  .col-2-xl,
  .col-3-xl,
  .col-4-xl,
  .col-5-xl,
  .col-6-xl,
  .col-7-xl,
  .col-8-xl,
  .col-9-xl,
  .col-10-xl,
  .col-11-xl,
  .col-12-xl {
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    float: left; }
  .col-1-xl {
    width: 8.33333%; }
  .col-2-xl {
    width: 16.66667%; }
  .col-3-xl {
    width: 25%; }
  .col-4-xl {
    width: 33.33333%; }
  .col-5-xl {
    width: 41.66667%; }
  .col-6-xl {
    width: 50%; }
  .col-7-xl {
    width: 58.33333%; }
  .col-8-xl {
    width: 66.66667%; }
  .col-9-xl {
    width: 75%; }
  .col-10-xl {
    width: 83.33333%; }
  .col-11-xl {
    width: 91.66667%; }
  .col-12-xl {
    width: 100%; } }

.hamburger span, .hamburger span:before, .hamburger span:after {
  width: 32px;
  height: 3px;
  background-color: #e5e1ed;
  display: block; }

.hamburger-wrapper {
  z-index: 9;
  position: relative; }

.hamburger {
  margin-right: 0;
  cursor: pointer;
  background: transparent;
  padding: 0;
  z-index: 10;
  margin-top: 33px;
  height: 20px;
  display: block; }
  @media only screen and (min-width: 740px) {
    .hamburger {
      display: none; } }
  .hamburger span {
    transition-duration: 0s;
    margin: 0 auto;
    position: relative;
    top: 8px;
    width: 32px;
    border-radius: 2px; }
    .hamburger span:before {
      transition-property: margin, transform;
      transition-duration: 0.2s;
      margin-top: -8px;
      position: absolute;
      content: "";
      width: 32px; }
    .hamburger span:after {
      transition-property: margin, transform;
      transition-duration: 0.2s;
      margin-top: 8px;
      position: absolute;
      content: "";
      width: 32px; }

.coloured-overlay {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #f5fbff;
  z-index: 12;
  right: 0;
  transform: scaleX(0);
  transition: 300ms transform cubic-bezier(0.4, 0, 0.2, 1) 400ms;
  overflow: hidden;
  transform-origin: top right; }
  .coloured-overlay.open {
    transform: scaleX(1);
    transform-origin: top left;
    transition: 300ms transform cubic-bezier(0.53, -0.01, 0.13, 0.82); }

.close-menu-button {
  position: absolute;
  top: 45px;
  right: 35px; }

.mobile-menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #ff4547;
  z-index: 12;
  right: 0;
  transform: scaleX(0);
  transition: 300ms transform cubic-bezier(0.4, 0, 0.2, 1);
  overflow: hidden;
  transform-origin: top right; }
  .mobile-menu ul {
    margin: 0;
    padding: 140px 40px;
    opacity: 0;
    transition: 200ms ease; }
    .mobile-menu ul li {
      list-style-type: none; }
      .mobile-menu ul li a {
        display: block;
        padding: 16px 10px 12px 0;
        font-size: 32px;
        line-height: 32px;
        font-weight: 600;
        text-transform: capitalize;
        text-decoration: none;
        color: #fff;
        text-align: center;
        transform: translate3d(-13em, 0, 0);
        transition: transform 0s 0.4s; }
        .mobile-menu ul li a:hover {
          text-decoration: none; }
      .mobile-menu ul li.current_page_item a {
        background: white;
        color: orange; }
    .mobile-menu ul ul {
      padding: 0;
      margin: 0; }
  .mobile-menu.open {
    transform: scaleX(1);
    width: 100%;
    transition: 303ms transform cubic-bezier(0.53, -0.01, 0.13, 0.82) 300ms;
    transform-origin: top left; }
    .mobile-menu.open ul {
      opacity: 1;
      transition: 1ms ease 600ms; }
    .mobile-menu.open ul li a {
      transform: translate3d(0, 0, 0);
      transition: opacity 0.4s 600ms, transform 0.4s 600ms cubic-bezier(0.645, 0.045, 0.355, 1); }

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em; }

.loader {
  margin: 0 auto 20px;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid #fff;
  border-right: 1.1em solid #fff;
  border-bottom: 1.1em solid #fff;
  border-left: 1.1em solid #6761fc;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear; }

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.loading-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #ffffff91;
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .loading-wrapper p {
    text-align: center;
    font-size: 26px;
    margin: 0; }

.loading-wrapper-initial {
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
  height: 100vh; }
  .loading-wrapper-initial p {
    text-align: center;
    font-size: 26px;
    margin: 0;
    font-weight: bold; }

#header {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  overflow: hidden; }
  #header .inner-header {
    position: relative;
    z-index: 2;
    display: flex;
    width: 100%;
    padding-bottom: 40px;
    max-width: calc(100% - 30px);
    margin: auto; }
    @media only screen and (min-width: 1140px) {
      #header .inner-header {
        max-width: calc(100% - 180px); } }
    #header .inner-header .header-logo {
      padding: 20px 0 0 0;
      transition: transform 300ms ease;
      display: inline-block;
      transform: scale(1); }
      @media only screen and (min-width: 740px) {
        #header .inner-header .header-logo {
          padding: 30px 60px 0 15px; } }
      @media only screen and (min-width: 1140px) {
        #header .inner-header .header-logo {
          padding: 30px 0 0 15px; } }
      #header .inner-header .header-logo:hover {
        transform: scale(0.95);
        transition: transform 300ms ease; }
      #header .inner-header .header-logo img {
        max-width: 540px;
        height: auto;
        width: 40vw;
        min-width: 260px; }
        @media only screen and (min-width: 980px) {
          #header .inner-header .header-logo img {
            width: 25vw; } }
  #header .header-menu {
    padding: 30px 15px 0 0;
    display: none; }
    @media only screen and (min-width: 740px) {
      #header .header-menu {
        display: block; } }
    #header .header-menu ul {
      margin: 0;
      padding: 0; }
      #header .header-menu ul li {
        list-style-type: none; }
        #header .header-menu ul li a.menubutton {
          font-size: 22px;
          line-height: 52px;
          color: #fff;
          text-transform: uppercase;
          font-weight: bold;
          letter-spacing: 8.73px;
          text-decoration: none;
          transition: color 300ms ease; }
          #header .header-menu ul li a.menubutton:hover {
            color: #ff4547;
            transition: color 300ms ease; }
  #header .container {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center; }
  #header h1 {
    color: #ffffff;
    text-align: center;
    line-height: 56px;
    font-size: 50px;
    font-weight: bold; }
    @media only screen and (min-width: 740px) {
      #header h1 {
        line-height: 5vw;
        font-size: 5vw; } }
    @media only screen and (min-width: 980px) {
      #header h1 {
        line-height: 4vw;
        font-size: 4vw; } }
  #header h2 {
    font-size: 26px;
    color: #ffffff;
    letter-spacing: 0;
    text-align: center;
    line-height: 44px;
    font-weight: 300; }
    @media only screen and (min-width: 740px) {
      #header h2 {
        line-height: 54px;
        font-size: 36px; } }
  #header .button-container {
    display: flex;
    padding-top: 40px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
    @media only screen and (min-width: 740px) {
      #header .button-container {
        flex-direction: row; } }
    #header .button-container .or {
      font-size: 30px;
      font-style: italic;
      color: #e5e1ee;
      text-align: center;
      padding-bottom: 10px;
      font-size: 0; }
      @media only screen and (min-width: 740px) {
        #header .button-container .or {
          padding: 0 40px;
          font-size: 30px; } }
  #header .container {
    padding-bottom: 40px; }

/*Basic Grid Styles*/
footer {
  background: #2e2e2e; }

.mainfooter {
  background: #2e2e2e;
  padding: 45px 0;
  text-align: center;
  font-size: 14px;
  color: #ff4547;
  letter-spacing: 0;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0; }
  .mainfooter a {
    color: #ff4547;
    text-decoration: none;
    padding: 0 20px;
    transition: color 300ms ease; }
    .mainfooter a:hover {
      color: #fff;
      transition: color 300ms ease; }
    .mainfooter a.linkedin-button {
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .mainfooter a.linkedin-button img {
        opacity: 1;
        transition: opacity 300ms ease; }
      .mainfooter a.linkedin-button:hover img {
        opacity: 0.7;
        transition: opacity 300ms ease; }
  .mainfooter span {
    padding: 0 20px;
    color: #fff;
    padding-bottom: 15px;
    display: block; }

html,
body {
  margin: 0;
  padding: 0;
  height: 100%; }

body #wrapper {
  background: white;
  color: #282828;
  display: flex;
  flex-direction: column;
  min-height: 100vh; }

#footer {
  margin-top: auto;
  background: grey; }

.container.hello-section {
  margin-bottom: 40px;
  display: block; }
  @media only screen and (min-width: 740px) {
    .container.hello-section {
      margin-bottom: 80px; } }
  .container.hello-section p {
    color: #eaecf1;
    letter-spacing: 0;
    line-height: 65px;
    font-size: 100px;
    padding-left: 0;
    font-weight: bold;
    padding-top: 40px;
    text-align: center; }
    @media only screen and (min-width: 740px) {
      .container.hello-section p {
        font-size: 200px;
        padding-left: 40px;
        line-height: 215px;
        text-align: left;
        padding-top: 20px; } }
    @media only screen and (min-width: 1140px) {
      .container.hello-section p {
        font-size: 13vw;
        line-height: 13vw;
        padding-left: 13vw;
        text-align: left;
        padding-top: 50px; } }
  .container.hello-section h3 {
    font-weight: bold;
    font-size: 38px;
    line-height: 52px;
    color: #2d3047;
    letter-spacing: 0;
    text-align: center; }
    @media only screen and (min-width: 740px) {
      .container.hello-section h3 {
        font-size: 48px;
        line-height: 60px; } }
    @media only screen and (min-width: 980px) {
      .container.hello-section h3 {
        font-size: 2.8vw;
        line-height: 2.8vw; } }
    .container.hello-section h3 img {
      padding: 0 15px; }

#testimonials {
  padding-top: 30px;
  padding-bottom: 30px;
  background: #eaecf1; }
  @media only screen and (min-width: 740px) {
    #testimonials {
      padding-top: 70px;
      padding-bottom: 70px; } }
  @media only screen and (min-width: 980px) {
    #testimonials {
      padding: 80px 15px; } }
  #testimonials .testimonial {
    padding: 20px; }
    #testimonials .testimonial .inner {
      background: #fff;
      padding: 60px 25px;
      box-shadow: 0 0 44px 0 rgba(0, 0, 0, 0.17); }
      #testimonials .testimonial .inner .custom-links {
        text-align: center;
        text-decoration: none;
        display: block;
        font-weight: 900;
        color: #ff4547;
        transition: color 300ms ease;
        padding-bottom: 15px;
        text-transform: uppercase; }
        #testimonials .testimonial .inner .custom-links:hover {
          color: #ff7879;
          transition: color 300ms ease; }
      #testimonials .testimonial .inner .description {
        color: #2e2e2e;
        font-weight: 300;
        text-align: center; }
      #testimonials .testimonial .inner .person {
        border-radius: 100%;
        background-position: center center;
        background-size: cover;
        height: 158px;
        width: 158px;
        display: inline-block;
        margin-bottom: 30px; }

#contact-section {
  padding-bottom: 50px;
  padding-top: 50px;
  background: #eaecf1; }
  @media only screen and (min-width: 740px) {
    #contact-section {
      padding-bottom: 80px;
      padding-top: 80px; } }
  #contact-section .title {
    font-weight: bold;
    font-size: 48px;
    color: #2d3047;
    line-height: 72px; }
  #contact-section .description {
    font-size: 18px;
    color: #2d3047;
    letter-spacing: 0;
    line-height: 27px; }
    #contact-section .description li {
      list-style-position: outside;
      padding-bottom: 15px; }
  #contact-section .email-footer {
    font-weight: bold;
    font-size: 24px;
    color: #ff4547;
    text-decoration: none;
    display: block;
    line-height: 32px;
    padding-bottom: 25px;
    transition: color 300ms ease; }
    #contact-section .email-footer:hover {
      color: #ff696a;
      transition: color 300ms ease; }
  #contact-section .phone-footer {
    font-weight: bold;
    font-size: 24px;
    color: #ff4547;
    text-decoration: none;
    display: block;
    line-height: 32px;
    padding-bottom: 25px;
    transition: color 300ms ease; }
    #contact-section .phone-footer:hover {
      color: #ff696a;
      transition: color 300ms ease; }
  #contact-section .description {
    font-size: 18px;
    color: #2d3047;
    letter-spacing: 0;
    line-height: 27px; }

#skill-used {
  background: #2d3047;
  padding-bottom: 15px;
  overflow: hidden;
  padding-bottom: 50px;
  padding-top: 50px; }
  @media only screen and (min-width: 740px) {
    #skill-used {
      padding: 80px 15px; } }
  #skill-used .leftside {
    color: #fff;
    padding-bottom: 30px; }
    @media only screen and (min-width: 740px) {
      #skill-used .leftside {
        padding-bottom: 20px; } }
    #skill-used .leftside .title {
      font-size: 42px;
      line-height: 52px;
      font-weight: bold;
      color: #fff;
      margin-bottom: 30px; }
  #skill-used .container {
    display: flex;
    flex-direction: column;
    padding: 0; }
    @media only screen and (min-width: 740px) {
      #skill-used .container {
        flex-wrap: wrap;
        max-width: 1600px;
        flex-direction: row;
        align-items: center; } }
  #skill-used .skills-wrapper {
    justify-content: center; }
    #skill-used .skills-wrapper img {
      padding: 0 10px 20px;
      cursor: pointer; }
  #skill-used img {
    max-width: 100%;
    height: auto; }

#recent-jobs {
  margin-top: 0;
  background: #fff;
  padding: 30px 0 30px 0;
  text-align: center; }
  @media only screen and (min-width: 740px) {
    #recent-jobs {
      padding: 80px 0 80px 0; } }
  #recent-jobs p {
    margin: 0; }
  #recent-jobs .title {
    font-size: 42px;
    line-height: 52px;
    font-weight: bold;
    margin-bottom: 30px; }
  #recent-jobs .description {
    font-size: 18px;
    line-height: 27px; }

#some-projects {
  background: #fff;
  padding-bottom: 0; }
  @media only screen and (min-width: 740px) {
    #some-projects {
      padding-bottom: 5px; } }
  @media only screen and (min-width: 980px) {
    #some-projects {
      padding-bottom: 80px; } }
  #some-projects .container {
    padding: 0; }
    @media only screen and (min-width: 740px) {
      #some-projects .container {
        padding: 0 5px; } }
    @media only screen and (min-width: 980px) {
      #some-projects .container {
        padding: 0 15px; } }
  #some-projects .some-projects-blocks {
    text-decoration: none;
    position: relative;
    cursor: pointer;
    overflow: hidden; }
    #some-projects .some-projects-blocks .cover {
      z-index: 4; }
    #some-projects .some-projects-blocks:hover .background-colour {
      opacity: 0;
      transition: opacity 300ms ease; }
    #some-projects .some-projects-blocks:hover .text-wrapping {
      opacity: 0;
      transition: opacity 0.35s cubic-bezier(0.4, 0, 0.2, 1); }
    #some-projects .some-projects-blocks:hover img {
      transform: scale(1.05);
      transition: transform 300ms ease; }
    #some-projects .some-projects-blocks .visit-site {
      color: #fff;
      font-size: 18px;
      line-height: 22px;
      font-weight: 300;
      display: block; }
    #some-projects .some-projects-blocks .flex-grow-1 {
      display: flex;
      flex-grow: 1; }
    #some-projects .some-projects-blocks .title {
      position: relative;
      z-index: 2;
      color: #fff;
      font-size: 22px;
      line-height: 33px;
      font-weight: 600;
      letter-spacing: 1px;
      display: block; }
    #some-projects .some-projects-blocks .text-wrapping {
      position: relative;
      z-index: 2;
      opacity: 1;
      transition: opacity 0.35s cubic-bezier(0.4, 0, 0.2, 1); }
    #some-projects .some-projects-blocks .background-colour {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      mix-blend-mode: multiply;
      opacity: 1;
      transition: opacity 0.35s cubic-bezier(0.4, 0, 0.2, 1); }
    #some-projects .some-projects-blocks .projects-img-container {
      width: 100%;
      padding-bottom: 56.3%;
      position: relative;
      background: #d1d3d8; }
      #some-projects .some-projects-blocks .projects-img-container img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        transform: scale(1);
        transition: transform 300ms ease;
        align-self: center; }
    #some-projects .some-projects-blocks .block-wrapper {
      position: relative;
      display: flex;
      flex-grow: 1;
      margin: 0px 0 5px 0;
      overflow: hidden; }
      @media only screen and (min-width: 740px) {
        #some-projects .some-projects-blocks .block-wrapper {
          margin: 5px; } }
      @media only screen and (min-width: 980px) {
        #some-projects .some-projects-blocks .block-wrapper {
          margin: 20px; } }
    #some-projects .some-projects-blocks .text-wrap {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 20px; }
    #some-projects .some-projects-blocks.project-block0 .background-colour {
      background: rgba(42, 45, 52, 0.27); }
    #some-projects .some-projects-blocks.project-block1 .background-colour {
      background: #6db2c4; }
    #some-projects .some-projects-blocks.project-block2 .background-colour {
      background: #6d9ec4; }
    #some-projects .some-projects-blocks.project-block3 .background-colour {
      background: #c1c46d; }
    #some-projects .some-projects-blocks.project-block4 .background-colour {
      background: #c4a76d; }
    #some-projects .some-projects-blocks.project-block5 .background-colour {
      background: #6dc4a2; }
    #some-projects .some-projects-blocks.project-block6 .background-colour {
      background: #99c46d; }
    #some-projects .some-projects-blocks.project-block7 .background-colour {
      background: #99c46d; }

.about-me {
  padding-bottom: 0; }
  @media only screen and (min-width: 740px) {
    .about-me {
      padding-bottom: 0px; } }
  @media only screen and (min-width: 980px) {
    .about-me {
      padding-bottom: 80px; } }
  @media only screen and (min-width: 1400px) {
    .about-me {
      padding-bottom: 260px; } }
  .about-me .container {
    display: flex;
    flex-direction: column;
    padding: 0; }
    @media only screen and (min-width: 740px) {
      .about-me .container {
        flex-direction: row; } }
    @media only screen and (min-width: 980px) {
      .about-me .container {
        display: block;
        padding: 0 50px; } }
  .about-me .leftside {
    background: #2706a0;
    padding: 30px 15px 40px 15px;
    position: relative;
    z-index: 2;
    order: 2;
    text-align: center; }
    @media only screen and (min-width: 740px) {
      .about-me .leftside {
        top: 0;
        text-align: left;
        margin-right: 0;
        order: unset;
        padding: 30px 30px 40px 30px;
        max-width: calc(70% - 60px); } }
    @media only screen and (min-width: 740px) {
      .about-me .leftside {
        max-width: calc(70% - 30px); } }
    @media only screen and (min-width: 1400px) {
      .about-me .leftside {
        top: 160px;
        max-width: calc(100% - 670px); } }
    .about-me .leftside h3 {
      font-weight: bold;
      font-size: 48px;
      color: #ffffff;
      letter-spacing: 0;
      line-height: 72px;
      padding-left: 20px; }
    .about-me .leftside .description {
      font-size: 20px;
      color: #ffffff;
      letter-spacing: 0.5px;
      line-height: 32px; }
  .about-me .rightside {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 30%; }
    @media only screen and (min-width: 740px) {
      .about-me .rightside {
        width: calc(30% + 30px);
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto; } }
    @media only screen and (min-width: 980px) {
      .about-me .rightside {
        right: 50px; } }
    @media only screen and (min-width: 1400px) {
      .about-me .rightside {
        width: auto;
        max-width: 100%;
        height: auto; } }
    .about-me .rightside img {
      display: block;
      max-width: 100%;
      height: auto; }
      @media only screen and (min-width: 740px) {
        .about-me .rightside img {
          display: none; } }
      @media only screen and (min-width: 1400px) {
        .about-me .rightside img {
          display: block;
          box-shadow: 0px 2px 70px rgba(0, 0, 0, 0.29); } }

html {
  height: 100%;
  box-sizing: border-box; }

*,
*:before,
*:after {
  box-sizing: inherit; }

.container-fluid,
.container {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto; }

.cursor {
  cursor: pointer; }

.margin-top-30 {
  margin-top: 30px; }

.flex-grow-1 {
  flex-grow: 1; }

.no-margin {
  margin: 0; }

.no-padding {
  padding-left: 0;
  padding-right: 0; }

.no-float {
  float: none; }

.no-background {
  background: transparent; }

.no-border {
  border: 0; }

.no-select {
  user-select: none;
  cursor: default; }

.margin-top-auto {
  margin-top: auto; }

.font-300 {
  font-weight: 300; }

.font-400 {
  font-weight: 400; }

.font-500 {
  font-weight: 500; }

.font-600 {
  font-weight: 600; }

.clear {
  clear: both; }

.overflow {
  overflow: hidden; }

.no-decoration {
  text-decoration: none; }
  .no-decoration:hover {
    text-decoration: none; }
  .no-decoration a {
    text-decoration: none; }
    .no-decoration a:hover {
      text-decoration: none; }

.margin-left-auto {
  margin-left: auto; }

.margin-right-auto {
  margin-right: auto; }

.font-normal {
  font-style: normal; }

.font-italic {
  font-style: italic; }

.uppercase {
  text-transform: uppercase; }

.lowercase {
  text-transform: lowercase; }

.capitalize {
  text-transform: capitalize; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

.relative {
  position: relative; }

.absolute {
  position: absolute; }

.static {
  position: static; }

.fixed {
  position: fixed; }

.none {
  display: none; }

.block {
  display: block; }

.inline-block {
  display: inline-block; }

.inline {
  display: inline; }

.flex {
  display: flex; }

.flex-row {
  flex-direction: row; }

.flex-column {
  flex-direction: column; }

.flex-space-around {
  justify-content: space-around; }

.flex-space-between {
  justify-content: space-between; }

.flex-start {
  justify-content: flex-start; }

.flex-center {
  justify-content: center; }

.flex-end {
  justify-content: flex-end; }

.flex-wrap {
  flex-wrap: wrap; }

.flex-nowrap {
  flex-wrap: nowrap; }

.align-items {
  align-items: center; }

.flex-fill {
  flex: 1 0 auto; }

.align-items-end {
  align-items: end; }

.left {
  float: left; }

.right {
  float: right; }

.center {
  float: none;
  margin-left: auto;
  margin-right: auto; }

.pad-top-5 {
  padding-top: 5px; }

.pad-top-10 {
  padding-top: 10px; }

.pad-top-15 {
  padding-top: 15px; }

.pad-top-20 {
  padding-top: 20px; }

.pad-top-25 {
  padding-top: 25px; }

.pad-top-30 {
  padding-top: 30px; }

.pad-top-35 {
  padding-top: 35px; }

.pad-top-40 {
  padding-top: 40px; }

.pad-top-45 {
  padding-top: 45px; }

.pad-top-50 {
  padding-top: 50px; }

.pad-top-55 {
  padding-top: 55px; }

.pad-top-60 {
  padding-top: 60px; }

.pad-top-70 {
  padding-top: 70px; }

.pad-top-80 {
  padding-top: 80px; }

.pad-top-100 {
  padding-top: 100px; }

.pad-bottom-5 {
  padding-bottom: 5px; }

.pad-bottom-10 {
  padding-bottom: 10px; }

.pad-bottom-15 {
  padding-bottom: 15px; }

.pad-bottom-20 {
  padding-bottom: 20px; }

.pad-bottom-25 {
  padding-bottom: 25px; }

.pad-bottom-30 {
  padding-bottom: 30px; }

.pad-bottom-35 {
  padding-bottom: 35px; }

.pad-bottom-40 {
  padding-bottom: 40px; }

.pad-bottom-45 {
  padding-bottom: 45px; }

.pad-bottom-50 {
  padding-bottom: 50px; }

.pad-bottom-55 {
  padding-bottom: 55px; }

.pad-bottom-60 {
  padding-bottom: 60px; }

.pad-bottom-70 {
  padding-bottom: 70px; }

.pad-bottom-80 {
  padding-bottom: 80px; }

.pad-bottom-100 {
  padding-bottom: 100px; }

.pad-5 {
  padding: 5px; }

.pad-10 {
  padding: 10px; }

.pad-15 {
  padding: 15px; }

.pad-20 {
  padding: 20px; }

.pad-25 {
  padding: 25px; }

.pad-30 {
  padding: 30px; }

.pad-35 {
  padding: 35px; }

.pad-40 {
  padding: 40px; }

.pad-45 {
  padding: 45px; }

.pad-50 {
  padding: 50px; }

.pad-55 {
  padding: 55px; }

.pad-60 {
  padding: 60px; }

.order1 {
  order: 1; }

.order2 {
  order: 2; }

.padding {
  padding-left: 15px;
  padding-right: 15px; }

.pad-left-0 {
  padding-left: 0; }

.pad-left-5 {
  padding-left: 5px; }

.pad-left-10 {
  padding-left: 10px; }

.pad-left-15 {
  padding-left: 15px; }

.pad-left-20 {
  padding-left: 20px; }

.pad-left-30 {
  padding-left: 30px; }

.pad-left-40 {
  padding-left: 40px; }

.pad-left-50 {
  padding-left: 50px; }

.pad-right-0 {
  padding-right: 0; }

.pad-right-5 {
  padding-right: 5px; }

.pad-right-10 {
  padding-right: 10px; }

.pad-right-15 {
  padding-right: 15px; }

.pad-right-20 {
  padding-right: 20px; }

.pad-right-30 {
  padding-right: 30px; }

.pad-right-40 {
  padding-right: 40px; }

.pad-right-50 {
  padding-right: 50px; }

@media only screen and (min-width: 740px) {
  .flex-grow-1-m {
    flex-grow: 1; }
  .padding-m {
    padding-left: 15px;
    padding-right: 15px; }
  .pad-left-0-m {
    padding-left: 0; }
  .pad-left-5-m {
    padding-left: 5px; }
  .pad-left-10-m {
    padding-left: 10px; }
  .pad-left-15-m {
    padding-left: 15px; }
  .pad-left-20-m {
    padding-left: 20px; }
  .pad-left-30-m {
    padding-left: 30px; }
  .pad-left-40-m {
    padding-left: 40px; }
  .pad-left-50-m {
    padding-left: 50px; }
  .pad-right-0-m {
    padding-right: 0; }
  .pad-right-5-m {
    padding-right: 5px; }
  .pad-right-10-m {
    padding-right: 10px; }
  .pad-right-15-m {
    padding-right: 15px; }
  .pad-right-20-m {
    padding-right: 20px; }
  .pad-right-30-m {
    padding-right: 30px; }
  .pad-right-40-m {
    padding-right: 40px; }
  .pad-right-50-m {
    padding-right: 50px; }
  .margin-left-auto-m {
    margin-left: auto; }
  .margin-right-auto-m {
    margin-right: auto; }
  .align-items-m {
    align-items: center; }
  .order1-m {
    order: 1; }
  .order2-m {
    order: 2; }
  .margin-left-auto-m {
    margin-left: auto; }
  .no-float-m {
    float: none; }
  .no-padding-m {
    padding-left: 0;
    padding-right: 0; }
  .no-margin-m {
    margin: 0; }
  .relative-m {
    position: relative; }
  .absolute-m {
    position: absolute; }
  .static-m {
    position: static; }
  .fixed-m {
    position: fixed; }
  .none-m {
    display: none; }
  .block-m {
    display: block; }
  .inline-block-m {
    display: inline-block; }
  .inline-m {
    display: inline; }
  .flex-m {
    display: flex; }
  .flex-row-m {
    flex-direction: row; }
  .flex-column-m {
    flex-direction: column; }
  .flex-space-around-m {
    justify-content: space-around; }
  .flex-space-between-m {
    justify-content: space-between; }
  .flex-start-m {
    justify-content: flex-start; }
  .flex-center-m {
    justify-content: center; }
  .flex-end-m {
    justify-content: flex-end; }
  .flex-wrap-m {
    flex-wrap: wrap; }
  .flex-nowrap-m {
    flex-wrap: nowrap; }
  .flex-fill-m {
    flex: 1 0 auto; }
  .self-flex-end {
    align-self: flex-end; }
  .left-m {
    float: left; }
  .right-m {
    float: right; }
  .center-m {
    float: none;
    margin-left: auto;
    margin-right: auto; }
  .text-left-m {
    text-align: left; }
  .text-right-m {
    text-align: right; }
  .text-center-m {
    text-align: center; }
  .text-justify-m {
    text-align: justify; }
  .no-col-m {
    width: auto;
    float: none; }
  .no-push-m,
  .no-pull-m {
    left: 0; }
  .pad-top-0-m {
    padding-top: 0; }
  .pad-top-5-m {
    padding-top: 5px; }
  .pad-top-10-m {
    padding-top: 10px; }
  .pad-top-15-m {
    padding-top: 15px; }
  .pad-top-20-m {
    padding-top: 20px; }
  .pad-top-25-m {
    padding-top: 25px; }
  .pad-top-30-m {
    padding-top: 30px; }
  .pad-top-35-m {
    padding-top: 35px; }
  .pad-top-40-m {
    padding-top: 40px; }
  .pad-top-45-m {
    padding-top: 45px; }
  .pad-top-50-m {
    padding-top: 50px; }
  .pad-top-55-m {
    padding-top: 55px; }
  .pad-top-60-m {
    padding-top: 60px; }
  .pad-bottom-0-m {
    padding-bottom: 0; }
  .pad-bottom-5-m {
    padding-bottom: 5px; }
  .pad-bottom-10-m {
    padding-bottom: 10px; }
  .pad-bottom-15-m {
    padding-bottom: 15px; }
  .pad-bottom-20-m {
    padding-bottom: 20px; }
  .pad-bottom-25-m {
    padding-bottom: 25px; }
  .pad-bottom-30-m {
    padding-bottom: 30px; }
  .pad-bottom-35-m {
    padding-bottom: 35px; }
  .pad-bottom-40-m {
    padding-bottom: 40px; }
  .pad-bottom-45-m {
    padding-bottom: 45px; }
  .pad-bottom-50-m {
    padding-bottom: 50px; }
  .pad-bottom-55-m {
    padding-bottom: 55px; }
  .pad-bottom-60-m {
    padding-bottom: 60px; }
  .pad-0-m {
    padding: 0; }
  .pad-5-m {
    padding: 5px; }
  .pad-10-m {
    padding: 10px; }
  .pad-15-m {
    padding: 15px; }
  .pad-20-m {
    padding: 20px; }
  .pad-25-m {
    padding: 25px; }
  .pad-30-m {
    padding: 30px; }
  .pad-35-m {
    padding: 35px; }
  .pad-40-m {
    padding: 40px; }
  .pad-45-m {
    padding: 45px; }
  .pad-50-m {
    padding: 50px; }
  .pad-55-m {
    padding: 55px; }
  .pad-60-m {
    padding: 60px; } }

@media only screen and (min-width: 980px) {
  .flex-grow-1-l {
    flex-grow: 1; }
  .padding-l {
    padding-left: 15px;
    padding-right: 15px; }
  .pad-left-0-l {
    padding-left: 0; }
  .pad-left-5-l {
    padding-left: 5px; }
  .pad-left-10-l {
    padding-left: 10px; }
  .pad-left-15-l {
    padding-left: 15px; }
  .pad-left-20-l {
    padding-left: 20px; }
  .pad-left-30-l {
    padding-left: 30px; }
  .pad-left-40-l {
    padding-left: 40px; }
  .pad-left-50-l {
    padding-left: 50px; }
  .pad-right-0-l {
    padding-right: 0; }
  .pad-right-5-l {
    padding-right: 5px; }
  .pad-right-10-l {
    padding-right: 10px; }
  .pad-right-15-l {
    padding-right: 15px; }
  .pad-right-20-l {
    padding-right: 20px; }
  .pad-right-30-l {
    padding-right: 30px; }
  .pad-right-40-l {
    padding-right: 40px; }
  .pad-right-50-l {
    padding-left: 50px; }
  .margin-left-auto-l {
    margin-left: auto; }
  .margin-right-auto-l {
    margin-right: auto; }
  .align-items-l {
    align-items: center; }
  .order1-l {
    order: 1; }
  .order2-l {
    order: 2; }
  .margin-left-auto-l {
    margin-left: auto; }
  .no-float-l {
    float: none; }
  .no-padding-l {
    padding-left: 0;
    padding-right: 0; }
  .no-margin-l {
    margin: 0; }
  .relative-l {
    position: relative; }
  .absolute-l {
    position: absolute; }
  .static-l {
    position: static; }
  .fixed-l {
    position: fixed; }
  .none-l {
    display: none; }
  .block-l {
    display: block; }
  .inline-block-l {
    display: inline-block; }
  .inline-l {
    display: inline; }
  .flex-l {
    display: flex; }
  .flex-row-l {
    flex-direction: row; }
  .flex-column-l {
    flex-direction: column; }
  .flex-space-around-l {
    justify-content: space-around; }
  .flex-space-between-l {
    justify-content: space-between; }
  .flex-start-l {
    justify-content: flex-start; }
  .flex-center-l {
    justify-content: center; }
  .flex-end-l {
    justify-content: flex-end; }
  .flex-wrap-l {
    flex-wrap: wrap; }
  .flex-nowrap-l {
    flex-wrap: nowrap; }
  .flex-fill-l {
    flex: 1 0 auto; }
  .left-l {
    float: left; }
  .right-l {
    float: right; }
  .center-l {
    float: none;
    margin-left: auto;
    margin-right: auto; }
  .text-left-l {
    text-align: left; }
  .text-right-l {
    text-align: right; }
  .text-center-l {
    text-align: center; }
  .text-justify-l {
    text-align: justify; }
  .no-col-l {
    width: auto;
    float: none; }
  .no-push-l,
  .no-pull-l {
    left: 0; }
  .pad-top-0-l {
    padding-top: 0; }
  .pad-top-5-l {
    padding-top: 5px; }
  .pad-top-10-l {
    padding-top: 10px; }
  .pad-top-15-l {
    padding-top: 15px; }
  .pad-top-20-l {
    padding-top: 20px; }
  .pad-top-25-l {
    padding-top: 25px; }
  .pad-top-30-l {
    padding-top: 30px; }
  .pad-top-35-l {
    padding-top: 35px; }
  .pad-top-40-l {
    padding-top: 40px; }
  .pad-top-45-l {
    padding-top: 45px; }
  .pad-top-50-l {
    padding-top: 50px; }
  .pad-top-55-l {
    padding-top: 55px; }
  .pad-top-60-l {
    padding-top: 60px; }
  .pad-bottom-0-l {
    padding-bottom: 0; }
  .pad-bottom-5-l {
    padding-bottom: 5px; }
  .pad-bottom-10-l {
    padding-bottom: 10px; }
  .pad-bottom-15-l {
    padding-bottom: 15px; }
  .pad-bottom-20-l {
    padding-bottom: 20px; }
  .pad-bottom-25-l {
    padding-bottom: 25px; }
  .pad-bottom-30-l {
    padding-bottom: 30px; }
  .pad-bottom-35-l {
    padding-bottom: 35px; }
  .pad-bottom-40-l {
    padding-bottom: 40px; }
  .pad-bottom-45-l {
    padding-bottom: 45px; }
  .pad-bottom-50-l {
    padding-bottom: 50px; }
  .pad-bottom-55-l {
    padding-bottom: 55px; }
  .pad-bottom-60-l {
    padding-bottom: 60px; }
  .pad-0-l {
    padding: 0; }
  .pad-5-l {
    padding: 5px; }
  .pad-10-l {
    padding: 10px; }
  .pad-15-l {
    padding: 15px; }
  .pad-20-l {
    padding: 20px; }
  .pad-25-l {
    padding: 25px; }
  .pad-30-l {
    padding: 30px; }
  .pad-35-l {
    padding: 35px; }
  .pad-40-l {
    padding: 40px; }
  .pad-45-l {
    padding: 45px; }
  .pad-50-l {
    padding: 50px; }
  .pad-55-l {
    padding: 55px; }
  .pad-60-l {
    padding: 60px; } }

@media only screen and (min-width: 1140px) {
  .padding-xl {
    padding-left: 15px;
    padding-right: 15px; }
  .pad-left-0-xl {
    padding-left: 0; }
  .pad-left-5-xl {
    padding-left: 5px; }
  .pad-left-10-xl {
    padding-left: 10px; }
  .pad-left-15-xl {
    padding-left: 15px; }
  .pad-left-20-xl {
    padding-left: 20px; }
  .pad-left-30-xl {
    padding-left: 30px; }
  .pad-left-40-xl {
    padding-left: 40px; }
  .pad-left-50-xl {
    padding-left: 50px; }
  .pad-right-0-xl {
    padding-right: 0; }
  .pad-right-5-xl {
    padding-right: 5px; }
  .pad-right-10-xl {
    padding-right: 10px; }
  .pad-right-15-xl {
    padding-right: 15px; }
  .pad-right-20-xl {
    padding-right: 20px; }
  .pad-right-30-xl {
    padding-right: 30px; }
  .pad-right-40-xl {
    padding-right: 40px; }
  .pad-right-50-xl {
    padding-right: 50px; }
  .margin-left-auto-xl {
    margin-left: auto; }
  .margin-right-auto-xl {
    margin-right: auto; }
  .align-items-xl {
    align-items: center; }
  .order1-xl {
    order: 1; }
  .order2-xl {
    order: 2; }
  .no-float-xl {
    float: none; }
  .no-padding-xl {
    padding-left: 0;
    padding-right: 0; }
  .no-margin-xl {
    margin: 0; }
  .relative-xl {
    position: relative; }
  .absolute-xl {
    position: absolute; }
  .static-xl {
    position: static; }
  .fixed-xl {
    position: fixed; }
  .none-xl {
    display: none; }
  .block-xl {
    display: block; }
  .inline-block-xl {
    display: inline-block; }
  .inline-xl {
    display: inline; }
  .flex-xl {
    display: flex; }
  .flex-row-xl {
    flex-direction: row; }
  .flex-column-xl {
    flex-direction: column; }
  .flex-space-around-xl {
    justify-content: space-around; }
  .flex-space-between-xl {
    justify-content: space-between; }
  .flex-start-xl {
    justify-content: flex-start; }
  .flex-center-xl {
    justify-content: center; }
  .flex-end-xl {
    justify-content: flex-end; }
  .flex-wrap-xl {
    flex-wrap: wrap; }
  .flex-nowrap-xl {
    flex-wrap: nowrap; }
  .flex-fill-xl {
    flex: 1 0 auto; }
  .left-xl {
    float: left; }
  .right-xl {
    float: right; }
  .center-xl {
    float: none;
    margin-left: auto;
    margin-right: auto; }
  .text-left-xl {
    text-align: left; }
  .text-right-xl {
    text-align: right; }
  .text-center-xl {
    text-align: center; }
  .text-justify-xl {
    text-align: justify; }
  .no-col-xl {
    width: auto;
    float: none; }
  .no-push-xl,
  .no-pull-xl {
    left: 0; }
  .pad-top-0-xl {
    padding-top: 0; }
  .pad-top-5-xl {
    padding-top: 5px; }
  .pad-top-10-xl {
    padding-top: 10px; }
  .pad-top-15-xl {
    padding-top: 15px; }
  .pad-top-20-xl {
    padding-top: 20px; }
  .pad-top-25-xl {
    padding-top: 25px; }
  .pad-top-30-xl {
    padding-top: 30px; }
  .pad-top-35-xl {
    padding-top: 35px; }
  .pad-top-40-xl {
    padding-top: 40px; }
  .pad-top-45-xl {
    padding-top: 45px; }
  .pad-top-50-xl {
    padding-top: 50px; }
  .pad-top-55-xl {
    padding-top: 55px; }
  .pad-top-60-xl {
    padding-top: 60px; }
  .pad-bottom-0-xl {
    padding-bottom: 0; }
  .pad-bottom-5-xl {
    padding-bottom: 5px; }
  .pad-bottom-10-xl {
    padding-bottom: 10px; }
  .pad-bottom-15-xl {
    padding-bottom: 15px; }
  .pad-bottom-20-xl {
    padding-bottom: 20px; }
  .pad-bottom-25-xl {
    padding-bottom: 25px; }
  .pad-bottom-30-xl {
    padding-bottom: 30px; }
  .pad-bottom-35-xl {
    padding-bottom: 35px; }
  .pad-bottom-40-xl {
    padding-bottom: 40px; }
  .pad-bottom-45-xl {
    padding-bottom: 45px; }
  .pad-bottom-50-xl {
    padding-bottom: 50px; }
  .pad-bottom-55-xl {
    padding-bottom: 55px; }
  .pad-bottom-60-xl {
    padding-bottom: 60px; }
  .pad-0-xl {
    padding: 0; }
  .pad-5-xl {
    padding: 5px; }
  .pad-10-xl {
    padding: 10px; }
  .pad-15-xl {
    padding: 15px; }
  .pad-20-xl {
    padding: 20px; }
  .pad-25-xl {
    padding: 25px; }
  .pad-30-xl {
    padding: 30px; }
  .pad-35-xl {
    padding: 35px; }
  .pad-40-xl {
    padding: 40px; }
  .pad-45-xl {
    padding: 45px; }
  .pad-50-xl {
    padding: 50px; }
  .pad-55-xl {
    padding: 55px; }
  .pad-60-xl {
    padding: 60px; } }

.full-width {
  width: 100%; }

.full-height {
  height: 100%; }

.z-index-1 {
  z-index: 1; }

.z-index-2 {
  z-index: 2; }

.z-index-3 {
  z-index: 3; }

.z-index-4 {
  z-index: 4; }

.z-index-5 {
  z-index: 5; }

.cover {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; }

.full-height {
  min-height: 100%; }

ul li {
  list-style-position: inside; }

.fluid-img {
  max-width: 100%;
  height: auto; }

.navigation.pagination {
  display: flex;
  padding-top: 40px;
  justify-content: center;
  width: 100%; }
  .navigation.pagination .nav-links {
    display: flex; }
  .navigation.pagination .page-numbers {
    padding: 10px 12px;
    background: #eaeaea;
    color: #666666;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 3px;
    border-radius: 3px;
    line-height: 51px;
    height: 37px;
    transition: background 300ms ease; }
    .navigation.pagination .page-numbers:hover {
      background: #e0e0e0;
      transition: background 300ms ease; }
    .navigation.pagination .page-numbers:focus {
      text-decoration: none; }
    .navigation.pagination .page-numbers.current {
      background: orange;
      color: white; }
      .navigation.pagination .page-numbers.current:hover {
        background: #eb9800;
        transition: background 300ms ease; }

.flexgrow-1 {
  flex-grow: 1;
  display: flex; }

a {
  cursor: pointer; }
