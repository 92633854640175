$hamburgerHeight: 35px;
$hamburgerWidth: 35px;
$hamburgerLineWidth: 20px;
$hamburgerSpacing: 7px;
$hamburgerColor: #2d3048;
$hamburgerBackground: transparent;

$mobileMenuBackground: #2d3048;
$mobileMenuBackgroundCurrent: #36b1b3;

$hamburgerHeight: 24px;
$hamburgerWidth: 32px;
$hamburgerLineWidth: 32px;
$hamburgerSpacing: 8px;
$hamburgerColor: #e5e1ed;
$hamburgerBackground: transparent;

%burgerSettings {
  width: $hamburgerWidth;
  height: 3px;
  background-color: $hamburgerColor;
  display: block;
}

.hamburger-wrapper {
  z-index: 9;
  position: relative;
}
.hamburger {
  margin-right: 0;
  cursor: pointer;
  background: transparent;
  padding: 0;
  z-index: 10;
  margin-top: 33px;
  height: 20px;
  display: block;
  // @include breakpoint(m) {
  //   margin-top: 46px;
  //   margin-right: 15px;
  // }
  @include breakpoint(m) {
    display: none;
  }
  span {
    transition-duration: 0s;
    margin: 0 auto;
    position: relative;
    top: $hamburgerSpacing;
    @extend %burgerSettings;
    width: $hamburgerLineWidth;
    border-radius: 2px;
    &:before {
      transition-property: margin, transform;
      transition-duration: 0.2s;
      margin-top: -$hamburgerSpacing;
      position: absolute;
      content: "";
      @extend %burgerSettings;
      width: $hamburgerLineWidth;
    }
    &:after {
      transition-property: margin, transform;
      transition-duration: 0.2s;
      margin-top: $hamburgerSpacing;
      position: absolute;
      content: "";
      @extend %burgerSettings;
      width: $hamburgerLineWidth;
    }
  }
}

.coloured-overlay {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #f5fbff;
  z-index: 12;
  right: 0;
  transform: scaleX(0);
  transition: 300ms transform cubic-bezier(0.4, 0, 0.2, 1) 400ms;
  overflow: hidden;
  transform-origin: top right;
  &.open {
    transform: scaleX(1);
    transform-origin: top left;
    transition: 300ms transform cubic-bezier(0.53, -0.01, 0.13, 0.82);
  }
}
.close-menu-button {
  position: absolute;
  top: 45px;
  right: 35px;
}
.mobile-menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: $red;
  z-index: 12;
  right: 0;
  transform: scaleX(0);
  transition: 300ms transform cubic-bezier(0.4, 0, 0.2, 1);
  overflow: hidden;
  transform-origin: top right;
  ul {
    margin: 0;
    padding: 140px 40px;
    opacity: 0;
    transition: 200ms ease;
    li {
      list-style-type: none;
      a {
        display: block;
        padding: 16px 10px 12px 0;
        font-size: 32px;
        line-height: 32px;
        font-weight: 600;
        text-transform: capitalize;
        text-decoration: none;
        color: #fff;
        text-align: center;
        transform: translate3d(-13em, 0, 0);
        transition: transform 0s 0.4s;
        &:hover {
          text-decoration: none;
        }
      }
      &.current_page_item {
        a {
          background: white;
          color: orange;
        }
      }
    }
    ul {
      padding: 0;
      margin: 0;
    }
  }
  &.open {
    transform: scaleX(1);
    width: 100%;
    transition: 303ms transform cubic-bezier(0.53, -0.01, 0.13, 0.82) 300ms;
    transform-origin: top left;
    ul {
      opacity: 1;
      transition: 1ms ease 600ms;
    }
    ul li a {
      transform: translate3d(0, 0, 0);
      transition: opacity 0.4s 600ms,
        transform 0.4s 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }
}
