footer {
  background: #2e2e2e;
}
.mainfooter {
  background: #2e2e2e;
  padding: 45px 0;
  text-align: center;
  font-size: 14px;
  color: $red;
  letter-spacing: 0;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  a {
    color: $red;
    text-decoration: none;
    padding: 0 20px;
    transition: color 300ms ease;
    &:hover {
      color: #fff;
      transition: color 300ms ease;
    }
    &.linkedin-button {
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        opacity: 1;
        transition: opacity 300ms ease;
      }
      &:hover {
        img {
          opacity: 0.7;
          transition: opacity 300ms ease;
        }
      }
    }
  }
  span {
    padding: 0 20px;
    color: #fff;
    padding-bottom: 15px;
    display: block;
  }
}
