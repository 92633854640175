#some-projects {
  background: #fff;
  padding-bottom: 0;
  @include breakpoint(m) {
    padding-bottom: 5px;
  }
  @include breakpoint(l) {
    padding-bottom: 80px;
  }
  .container {
    padding: 0;
    @include breakpoint(m) {
      padding: 0 5px;
    }
    @include breakpoint(l) {
      padding: 0 15px;
    }
  }
  .some-projects-blocks {
    text-decoration: none;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    .cover {
      z-index: 4;
    }
    &:hover {
      .background-colour {
        opacity: 0;
        transition: opacity 300ms ease;
      }
      .text-wrapping {
        opacity: 0;
        transition: opacity 0.35s cubic-bezier(0.4, 0, 0.2, 1);
      }
      img {
        transform: scale(1.05);
        transition: transform 300ms ease;
      }
    }
    .visit-site {
      color: #fff;
      font-size: 18px;
      line-height: 22px;
      font-weight: 300;
      display: block;
    }
    .flex-grow-1 {
      display: flex;
      flex-grow: 1;
    }
    .title {
      position: relative;
      z-index: 2;
      color: #fff;
      font-size: 22px;
      line-height: 33px;
      font-weight: 600;
      letter-spacing: 1px;
      display: block;
    }
    .text-wrapping {
      position: relative;
      z-index: 2;
      opacity: 1;
      transition: opacity 0.35s cubic-bezier(0.4, 0, 0.2, 1);
    }
    .background-colour {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      mix-blend-mode: multiply;
      opacity: 1;
      transition: opacity 0.35s cubic-bezier(0.4, 0, 0.2, 1);
    }
    .projects-img-container {
      width: 100%;
      padding-bottom: 56.3%;
      position: relative;
      background: #d1d3d8;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        transform: scale(1);
        transition: transform 300ms ease;
        align-self: center;
      }
    }
    .block-wrapper {
      position: relative;
      display: flex;
      flex-grow: 1;
      margin: 0px 0 5px 0;
      overflow: hidden;
      @include breakpoint(m) {
        margin: 5px;
      }
      @include breakpoint(l) {
        margin: 20px;
      }
    }
    .text-wrap {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 20px;
    }
    &.project-block0 {
      .background-colour {
        background: rgba(42, 45, 52, 0.27);
      }
    }
    &.project-block1 {
      .background-colour {
        background: #6db2c4;
      }
    }
    &.project-block2 {
      .background-colour {
        background: #6d9ec4;
      }
    }
    &.project-block3 {
      .background-colour {
        background: #c1c46d;
      }
    }
    &.project-block4 {
      .background-colour {
        background: #c4a76d;
      }
    }
    &.project-block5 {
      .background-colour {
        background: #6dc4a2;
      }
    }
    &.project-block6 {
      .background-colour {
        background: #99c46d;
      }
    }
    &.project-block7 {
      .background-colour {
        background: #99c46d;
      }
    }
  }
}
