// ==========================================================================
// A range of helper classes that speed up development
// ==========================================================================
//sitewide

//footer at bottom
html {
  height: 100%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.container-fluid,
.container {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
}

.cursor {
  cursor: pointer;
}

.margin-top-30 {
  margin-top: 30px;
}

.flex-grow-1 {
  flex-grow: 1;
}
// General Resets
.no-margin {
  margin: 0;
}
.no-padding {
  padding-left: 0;
  padding-right: 0;
}
.no-float {
  float: none;
}
.no-background {
  background: transparent;
}
.no-border {
  border: 0;
}
.no-select {
  user-select: none;
  cursor: default;
}

.margin-top-auto {
  margin-top: auto;
}

.font-300 {
  font-weight: 300;
}
.font-400 {
  font-weight: 400;
}
.font-500 {
  font-weight: 500;
}
.font-600 {
  font-weight: 600;
}

.clear {
  clear: both;
}

.overflow {
  overflow: hidden;
}

.no-decoration {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  a {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
}

.margin-left-auto {
  margin-left: auto;
}
.margin-right-auto {
  margin-right: auto;
}

// Font Styles
.font-normal {
  font-style: normal;
}
.font-italic {
  font-style: italic;
}

// Text Modifications
.uppercase {
  text-transform: uppercase;
}
.lowercase {
  text-transform: lowercase;
}
.capitalize {
  text-transform: capitalize;
}

// Text Alignments
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}

// Positions
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.static {
  position: static;
}
.fixed {
  position: fixed;
}

// Display Types
.none {
  display: none;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.inline {
  display: inline;
}

// Flex Types
.flex {
  display: flex;
}
.flex-row {
  flex-direction: row;
}
.flex-column {
  flex-direction: column;
}
.flex-space-around {
  justify-content: space-around;
}
.flex-space-between {
  justify-content: space-between;
}
.flex-start {
  justify-content: flex-start;
}
.flex-center {
  justify-content: center;
}
.flex-end {
  justify-content: flex-end;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-nowrap {
  flex-wrap: nowrap;
}
.align-items {
  align-items: center;
}
.flex-fill {
  flex: 1 0 auto;
}
.align-items-end {
  align-items: end;
}

// Floats
.left {
  float: left;
}
.right {
  float: right;
}

// Alignment
.center {
  float: none;
  margin-left: auto;
  margin-right: auto;
}

// Padding Helpers
.pad-top-5 {
  padding-top: 5px;
}
.pad-top-10 {
  padding-top: 10px;
}
.pad-top-15 {
  padding-top: 15px;
}
.pad-top-20 {
  padding-top: 20px;
}
.pad-top-25 {
  padding-top: 25px;
}
.pad-top-30 {
  padding-top: 30px;
}
.pad-top-35 {
  padding-top: 35px;
}
.pad-top-40 {
  padding-top: 40px;
}
.pad-top-45 {
  padding-top: 45px;
}
.pad-top-50 {
  padding-top: 50px;
}
.pad-top-55 {
  padding-top: 55px;
}
.pad-top-60 {
  padding-top: 60px;
}
.pad-top-70 {
  padding-top: 70px;
}
.pad-top-80 {
  padding-top: 80px;
}
.pad-top-100 {
  padding-top: 100px;
}
.pad-bottom-5 {
  padding-bottom: 5px;
}
.pad-bottom-10 {
  padding-bottom: 10px;
}
.pad-bottom-15 {
  padding-bottom: 15px;
}
.pad-bottom-20 {
  padding-bottom: 20px;
}
.pad-bottom-25 {
  padding-bottom: 25px;
}
.pad-bottom-30 {
  padding-bottom: 30px;
}
.pad-bottom-35 {
  padding-bottom: 35px;
}
.pad-bottom-40 {
  padding-bottom: 40px;
}
.pad-bottom-45 {
  padding-bottom: 45px;
}
.pad-bottom-50 {
  padding-bottom: 50px;
}
.pad-bottom-55 {
  padding-bottom: 55px;
}
.pad-bottom-60 {
  padding-bottom: 60px;
}
.pad-bottom-70 {
  padding-bottom: 70px;
}
.pad-bottom-80 {
  padding-bottom: 80px;
}
.pad-bottom-100 {
  padding-bottom: 100px;
}
.pad-5 {
  padding: 5px;
}
.pad-10 {
  padding: 10px;
}
.pad-15 {
  padding: 15px;
}
.pad-20 {
  padding: 20px;
}
.pad-25 {
  padding: 25px;
}
.pad-30 {
  padding: 30px;
}
.pad-35 {
  padding: 35px;
}
.pad-40 {
  padding: 40px;
}
.pad-45 {
  padding: 45px;
}
.pad-50 {
  padding: 50px;
}
.pad-55 {
  padding: 55px;
}
.pad-60 {
  padding: 60px;
}
.order1 {
  order: 1;
}
.order2 {
  order: 2;
}
.padding {
  padding-left: 15px;
  padding-right: 15px;
}

.pad-left-0 {
  padding-left: 0;
}
.pad-left-5 {
  padding-left: 5px;
}
.pad-left-10 {
  padding-left: 10px;
}
.pad-left-15 {
  padding-left: 15px;
}
.pad-left-20 {
  padding-left: 20px;
}
.pad-left-30 {
  padding-left: 30px;
}
.pad-left-40 {
  padding-left: 40px;
}
.pad-left-50 {
  padding-left: 50px;
}

.pad-right-0 {
  padding-right: 0;
}
.pad-right-5 {
  padding-right: 5px;
}
.pad-right-10 {
  padding-right: 10px;
}
.pad-right-15 {
  padding-right: 15px;
}
.pad-right-20 {
  padding-right: 20px;
}
.pad-right-30 {
  padding-right: 30px;
}
.pad-right-40 {
  padding-right: 40px;
}
.pad-right-50 {
  padding-right: 50px;
}

// Tablet Helpers
@include breakpoint(m) {
  .flex-grow-1-m {
    flex-grow: 1;
  }
  .padding-m {
    padding-left: 15px;
    padding-right: 15px;
  }
  .pad-left-0-m {
    padding-left: 0;
  }
  .pad-left-5-m {
    padding-left: 5px;
  }
  .pad-left-10-m {
    padding-left: 10px;
  }
  .pad-left-15-m {
    padding-left: 15px;
  }
  .pad-left-20-m {
    padding-left: 20px;
  }
  .pad-left-30-m {
    padding-left: 30px;
  }
  .pad-left-40-m {
    padding-left: 40px;
  }
  .pad-left-50-m {
    padding-left: 50px;
  }

  .pad-right-0-m {
    padding-right: 0;
  }
  .pad-right-5-m {
    padding-right: 5px;
  }
  .pad-right-10-m {
    padding-right: 10px;
  }
  .pad-right-15-m {
    padding-right: 15px;
  }
  .pad-right-20-m {
    padding-right: 20px;
  }
  .pad-right-30-m {
    padding-right: 30px;
  }
  .pad-right-40-m {
    padding-right: 40px;
  }
  .pad-right-50-m {
    padding-right: 50px;
  }

  .margin-left-auto-m {
    margin-left: auto;
  }
  .margin-right-auto-m {
    margin-right: auto;
  }
  .align-items-m {
    align-items: center;
  }
  .order1-m {
    order: 1;
  }
  .order2-m {
    order: 2;
  }
  .margin-left-auto-m {
    margin-left: auto;
  }
  // General Resets
  .no-float-m {
    float: none;
  }
  .no-padding-m {
    padding-left: 0;
    padding-right: 0;
  }
  .no-margin-m {
    margin: 0;
  }

  // Positions
  .relative-m {
    position: relative;
  }
  .absolute-m {
    position: absolute;
  }
  .static-m {
    position: static;
  }
  .fixed-m {
    position: fixed;
  }

  // Display Types
  .none-m {
    display: none;
  }
  .block-m {
    display: block;
  }
  .inline-block-m {
    display: inline-block;
  }
  .inline-m {
    display: inline;
  }

  // Flex Types
  .flex-m {
    display: flex;
  }
  .flex-row-m {
    flex-direction: row;
  }
  .flex-column-m {
    flex-direction: column;
  }
  .flex-space-around-m {
    justify-content: space-around;
  }
  .flex-space-between-m {
    justify-content: space-between;
  }
  .flex-start-m {
    justify-content: flex-start;
  }
  .flex-center-m {
    justify-content: center;
  }
  .flex-end-m {
    justify-content: flex-end;
  }
  .flex-wrap-m {
    flex-wrap: wrap;
  }
  .flex-nowrap-m {
    flex-wrap: nowrap;
  }
  .flex-fill-m {
    flex: 1 0 auto;
  }
  .self-flex-end {
    align-self: flex-end;
  }
  // Floats
  .left-m {
    float: left;
  }
  .right-m {
    float: right;
  }

  // Alignment
  .center-m {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }

  // Text Alignments
  .text-left-m {
    text-align: left;
  }
  .text-right-m {
    text-align: right;
  }
  .text-center-m {
    text-align: center;
  }
  .text-justify-m {
    text-align: justify;
  }

  // Grid Resets
  .no-col-m {
    width: auto;
    float: none;
  }
  .no-push-m,
  .no-pull-m {
    left: 0;
  }

  // Padding Helpers
  .pad-top-0-m {
    padding-top: 0;
  }
  .pad-top-5-m {
    padding-top: 5px;
  }
  .pad-top-10-m {
    padding-top: 10px;
  }
  .pad-top-15-m {
    padding-top: 15px;
  }
  .pad-top-20-m {
    padding-top: 20px;
  }
  .pad-top-25-m {
    padding-top: 25px;
  }
  .pad-top-30-m {
    padding-top: 30px;
  }
  .pad-top-35-m {
    padding-top: 35px;
  }
  .pad-top-40-m {
    padding-top: 40px;
  }
  .pad-top-45-m {
    padding-top: 45px;
  }
  .pad-top-50-m {
    padding-top: 50px;
  }
  .pad-top-55-m {
    padding-top: 55px;
  }
  .pad-top-60-m {
    padding-top: 60px;
  }
  .pad-bottom-0-m {
    padding-bottom: 0;
  }
  .pad-bottom-5-m {
    padding-bottom: 5px;
  }
  .pad-bottom-10-m {
    padding-bottom: 10px;
  }
  .pad-bottom-15-m {
    padding-bottom: 15px;
  }
  .pad-bottom-20-m {
    padding-bottom: 20px;
  }
  .pad-bottom-25-m {
    padding-bottom: 25px;
  }
  .pad-bottom-30-m {
    padding-bottom: 30px;
  }
  .pad-bottom-35-m {
    padding-bottom: 35px;
  }
  .pad-bottom-40-m {
    padding-bottom: 40px;
  }
  .pad-bottom-45-m {
    padding-bottom: 45px;
  }
  .pad-bottom-50-m {
    padding-bottom: 50px;
  }
  .pad-bottom-55-m {
    padding-bottom: 55px;
  }
  .pad-bottom-60-m {
    padding-bottom: 60px;
  }
  .pad-0-m {
    padding: 0;
  }
  .pad-5-m {
    padding: 5px;
  }
  .pad-10-m {
    padding: 10px;
  }
  .pad-15-m {
    padding: 15px;
  }
  .pad-20-m {
    padding: 20px;
  }
  .pad-25-m {
    padding: 25px;
  }
  .pad-30-m {
    padding: 30px;
  }
  .pad-35-m {
    padding: 35px;
  }
  .pad-40-m {
    padding: 40px;
  }
  .pad-45-m {
    padding: 45px;
  }
  .pad-50-m {
    padding: 50px;
  }
  .pad-55-m {
    padding: 55px;
  }
  .pad-60-m {
    padding: 60px;
  }
}

// Desktop Helpers
@include breakpoint(l) {
  .flex-grow-1-l {
    flex-grow: 1;
  }
  .padding-l {
    padding-left: 15px;
    padding-right: 15px;
  }
  .pad-left-0-l {
    padding-left: 0;
  }
  .pad-left-5-l {
    padding-left: 5px;
  }
  .pad-left-10-l {
    padding-left: 10px;
  }
  .pad-left-15-l {
    padding-left: 15px;
  }
  .pad-left-20-l {
    padding-left: 20px;
  }
  .pad-left-30-l {
    padding-left: 30px;
  }
  .pad-left-40-l {
    padding-left: 40px;
  }
  .pad-left-50-l {
    padding-left: 50px;
  }

  .pad-right-0-l {
    padding-right: 0;
  }
  .pad-right-5-l {
    padding-right: 5px;
  }
  .pad-right-10-l {
    padding-right: 10px;
  }
  .pad-right-15-l {
    padding-right: 15px;
  }
  .pad-right-20-l {
    padding-right: 20px;
  }
  .pad-right-30-l {
    padding-right: 30px;
  }
  .pad-right-40-l {
    padding-right: 40px;
  }
  .pad-right-50-l {
    padding-left: 50px;
  }

  .margin-left-auto-l {
    margin-left: auto;
  }
  .margin-right-auto-l {
    margin-right: auto;
  }
  .align-items-l {
    align-items: center;
  }
  .order1-l {
    order: 1;
  }
  .order2-l {
    order: 2;
  }
  .margin-left-auto-l {
    margin-left: auto;
  }
  // General Resets
  .no-float-l {
    float: none;
  }
  .no-padding-l {
    padding-left: 0;
    padding-right: 0;
  }
  .no-margin-l {
    margin: 0;
  }

  // Positions
  .relative-l {
    position: relative;
  }
  .absolute-l {
    position: absolute;
  }
  .static-l {
    position: static;
  }
  .fixed-l {
    position: fixed;
  }

  // Display Types
  .none-l {
    display: none;
  }
  .block-l {
    display: block;
  }
  .inline-block-l {
    display: inline-block;
  }
  .inline-l {
    display: inline;
  }

  // Flex Types
  .flex-l {
    display: flex;
  }
  .flex-row-l {
    flex-direction: row;
  }
  .flex-column-l {
    flex-direction: column;
  }
  .flex-space-around-l {
    justify-content: space-around;
  }
  .flex-space-between-l {
    justify-content: space-between;
  }
  .flex-start-l {
    justify-content: flex-start;
  }
  .flex-center-l {
    justify-content: center;
  }
  .flex-end-l {
    justify-content: flex-end;
  }
  .flex-wrap-l {
    flex-wrap: wrap;
  }
  .flex-nowrap-l {
    flex-wrap: nowrap;
  }
  .flex-fill-l {
    flex: 1 0 auto;
  }

  // Floats
  .left-l {
    float: left;
  }
  .right-l {
    float: right;
  }

  // Alignment
  .center-l {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }

  // Text Alignments
  .text-left-l {
    text-align: left;
  }
  .text-right-l {
    text-align: right;
  }
  .text-center-l {
    text-align: center;
  }
  .text-justify-l {
    text-align: justify;
  }

  // Grid Resets
  .no-col-l {
    width: auto;
    float: none;
  }
  .no-push-l,
  .no-pull-l {
    left: 0;
  }

  // Padding Helpers
  .pad-top-0-l {
    padding-top: 0;
  }
  .pad-top-5-l {
    padding-top: 5px;
  }
  .pad-top-10-l {
    padding-top: 10px;
  }
  .pad-top-15-l {
    padding-top: 15px;
  }
  .pad-top-20-l {
    padding-top: 20px;
  }
  .pad-top-25-l {
    padding-top: 25px;
  }
  .pad-top-30-l {
    padding-top: 30px;
  }
  .pad-top-35-l {
    padding-top: 35px;
  }
  .pad-top-40-l {
    padding-top: 40px;
  }
  .pad-top-45-l {
    padding-top: 45px;
  }
  .pad-top-50-l {
    padding-top: 50px;
  }
  .pad-top-55-l {
    padding-top: 55px;
  }
  .pad-top-60-l {
    padding-top: 60px;
  }
  .pad-bottom-0-l {
    padding-bottom: 0;
  }
  .pad-bottom-5-l {
    padding-bottom: 5px;
  }
  .pad-bottom-10-l {
    padding-bottom: 10px;
  }
  .pad-bottom-15-l {
    padding-bottom: 15px;
  }
  .pad-bottom-20-l {
    padding-bottom: 20px;
  }
  .pad-bottom-25-l {
    padding-bottom: 25px;
  }
  .pad-bottom-30-l {
    padding-bottom: 30px;
  }
  .pad-bottom-35-l {
    padding-bottom: 35px;
  }
  .pad-bottom-40-l {
    padding-bottom: 40px;
  }
  .pad-bottom-45-l {
    padding-bottom: 45px;
  }
  .pad-bottom-50-l {
    padding-bottom: 50px;
  }
  .pad-bottom-55-l {
    padding-bottom: 55px;
  }
  .pad-bottom-60-l {
    padding-bottom: 60px;
  }
  .pad-0-l {
    padding: 0;
  }
  .pad-5-l {
    padding: 5px;
  }
  .pad-10-l {
    padding: 10px;
  }
  .pad-15-l {
    padding: 15px;
  }
  .pad-20-l {
    padding: 20px;
  }
  .pad-25-l {
    padding: 25px;
  }
  .pad-30-l {
    padding: 30px;
  }
  .pad-35-l {
    padding: 35px;
  }
  .pad-40-l {
    padding: 40px;
  }
  .pad-45-l {
    padding: 45px;
  }
  .pad-50-l {
    padding: 50px;
  }
  .pad-55-l {
    padding: 55px;
  }
  .pad-60-l {
    padding: 60px;
  }
}

// HD Helpers
@include breakpoint(xl) {
  .padding-xl {
    padding-left: 15px;
    padding-right: 15px;
  }
  .pad-left-0-xl {
    padding-left: 0;
  }
  .pad-left-5-xl {
    padding-left: 5px;
  }
  .pad-left-10-xl {
    padding-left: 10px;
  }
  .pad-left-15-xl {
    padding-left: 15px;
  }
  .pad-left-20-xl {
    padding-left: 20px;
  }
  .pad-left-30-xl {
    padding-left: 30px;
  }
  .pad-left-40-xl {
    padding-left: 40px;
  }
  .pad-left-50-xl {
    padding-left: 50px;
  }

  .pad-right-0-xl {
    padding-right: 0;
  }
  .pad-right-5-xl {
    padding-right: 5px;
  }
  .pad-right-10-xl {
    padding-right: 10px;
  }
  .pad-right-15-xl {
    padding-right: 15px;
  }
  .pad-right-20-xl {
    padding-right: 20px;
  }
  .pad-right-30-xl {
    padding-right: 30px;
  }
  .pad-right-40-xl {
    padding-right: 40px;
  }
  .pad-right-50-xl {
    padding-right: 50px;
  }

  .margin-left-auto-xl {
    margin-left: auto;
  }
  .margin-right-auto-xl {
    margin-right: auto;
  }
  .align-items-xl {
    align-items: center;
  }
  .order1-xl {
    order: 1;
  }
  .order2-xl {
    order: 2;
  }
  // General Resets
  .no-float-xl {
    float: none;
  }
  .no-padding-xl {
    padding-left: 0;
    padding-right: 0;
  }
  .no-margin-xl {
    margin: 0;
  }

  // Positions
  .relative-xl {
    position: relative;
  }
  .absolute-xl {
    position: absolute;
  }
  .static-xl {
    position: static;
  }
  .fixed-xl {
    position: fixed;
  }

  // Display Types
  .none-xl {
    display: none;
  }
  .block-xl {
    display: block;
  }
  .inline-block-xl {
    display: inline-block;
  }
  .inline-xl {
    display: inline;
  }

  // Flex Types
  .flex-xl {
    display: flex;
  }
  .flex-row-xl {
    flex-direction: row;
  }
  .flex-column-xl {
    flex-direction: column;
  }
  .flex-space-around-xl {
    justify-content: space-around;
  }
  .flex-space-between-xl {
    justify-content: space-between;
  }
  .flex-start-xl {
    justify-content: flex-start;
  }
  .flex-center-xl {
    justify-content: center;
  }
  .flex-end-xl {
    justify-content: flex-end;
  }
  .flex-wrap-xl {
    flex-wrap: wrap;
  }
  .flex-nowrap-xl {
    flex-wrap: nowrap;
  }
  .flex-fill-xl {
    flex: 1 0 auto;
  }

  // Floats
  .left-xl {
    float: left;
  }
  .right-xl {
    float: right;
  }

  // Alignment
  .center-xl {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }

  // Text Alignments
  .text-left-xl {
    text-align: left;
  }
  .text-right-xl {
    text-align: right;
  }
  .text-center-xl {
    text-align: center;
  }
  .text-justify-xl {
    text-align: justify;
  }

  // Grid Resets
  .no-col-xl {
    width: auto;
    float: none;
  }
  .no-push-xl,
  .no-pull-xl {
    left: 0;
  }

  // Padding Helpers
  .pad-top-0-xl {
    padding-top: 0;
  }
  .pad-top-5-xl {
    padding-top: 5px;
  }
  .pad-top-10-xl {
    padding-top: 10px;
  }
  .pad-top-15-xl {
    padding-top: 15px;
  }
  .pad-top-20-xl {
    padding-top: 20px;
  }
  .pad-top-25-xl {
    padding-top: 25px;
  }
  .pad-top-30-xl {
    padding-top: 30px;
  }
  .pad-top-35-xl {
    padding-top: 35px;
  }
  .pad-top-40-xl {
    padding-top: 40px;
  }
  .pad-top-45-xl {
    padding-top: 45px;
  }
  .pad-top-50-xl {
    padding-top: 50px;
  }
  .pad-top-55-xl {
    padding-top: 55px;
  }
  .pad-top-60-xl {
    padding-top: 60px;
  }
  .pad-bottom-0-xl {
    padding-bottom: 0;
  }
  .pad-bottom-5-xl {
    padding-bottom: 5px;
  }
  .pad-bottom-10-xl {
    padding-bottom: 10px;
  }
  .pad-bottom-15-xl {
    padding-bottom: 15px;
  }
  .pad-bottom-20-xl {
    padding-bottom: 20px;
  }
  .pad-bottom-25-xl {
    padding-bottom: 25px;
  }
  .pad-bottom-30-xl {
    padding-bottom: 30px;
  }
  .pad-bottom-35-xl {
    padding-bottom: 35px;
  }
  .pad-bottom-40-xl {
    padding-bottom: 40px;
  }
  .pad-bottom-45-xl {
    padding-bottom: 45px;
  }
  .pad-bottom-50-xl {
    padding-bottom: 50px;
  }
  .pad-bottom-55-xl {
    padding-bottom: 55px;
  }
  .pad-bottom-60-xl {
    padding-bottom: 60px;
  }
  .pad-0-xl {
    padding: 0;
  }
  .pad-5-xl {
    padding: 5px;
  }
  .pad-10-xl {
    padding: 10px;
  }
  .pad-15-xl {
    padding: 15px;
  }
  .pad-20-xl {
    padding: 20px;
  }
  .pad-25-xl {
    padding: 25px;
  }
  .pad-30-xl {
    padding: 30px;
  }
  .pad-35-xl {
    padding: 35px;
  }
  .pad-40-xl {
    padding: 40px;
  }
  .pad-45-xl {
    padding: 45px;
  }
  .pad-50-xl {
    padding: 50px;
  }
  .pad-55-xl {
    padding: 55px;
  }
  .pad-60-xl {
    padding: 60px;
  }
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.z-index-1 {
  z-index: 1;
}
.z-index-2 {
  z-index: 2;
}
.z-index-3 {
  z-index: 3;
}
.z-index-4 {
  z-index: 4;
}
.z-index-5 {
  z-index: 5;
}

.cover {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.full-height {
  min-height: 100%;
}

ul li {
  list-style-position: inside;
}

.fluid-img {
  max-width: 100%;
  height: auto;
}

//pagination
.navigation.pagination {
  display: flex;
  padding-top: 40px;
  justify-content: center;
  width: 100%;
  .nav-links {
    display: flex;
  }
  .page-numbers {
    padding: 10px 12px;
    background: #eaeaea;
    color: #666666;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 3px;
    border-radius: 3px;
    line-height: 51px;
    height: 37px;
    transition: background 300ms ease;
    &:hover {
      background: darken(#eaeaea, 4%);
      transition: background 300ms ease;
    }
    &:focus {
      text-decoration: none;
    }
    &.current {
      background: orange;
      color: white;
      &:hover {
        background: darken(orange, 4%);
        transition: background 300ms ease;
      }
    }
  }
}

.flexgrow-1 {
  flex-grow: 1;
  display: flex;
}

a {
  cursor: pointer;
}
