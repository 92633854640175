// Header
#header {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  overflow: hidden;
  .headerbg {
  }
  .inner-header {
    position: relative;
    z-index: 2;
    display: flex;
    width: 100%;
    padding-bottom: 40px;
    max-width: calc(100% - 30px);
    margin: auto;
    @include breakpoint(xl) {
      max-width: calc(100% - 180px);
    }
    .header-logo {
      padding: 20px 0 0 0;
      transition: transform 300ms ease;
      display: inline-block;
      transform: scale(1);
      @include breakpoint(m) {
        padding: 30px 60px 0 15px;
      }
      @include breakpoint(xl) {
        padding: 30px 0 0 15px;
      }
      &:hover {
        transform: scale(0.95);
        transition: transform 300ms ease;
      }
      img {
        max-width: 540px;
        height: auto;
        width: 40vw;
        min-width: 260px;
        @include breakpoint(l) {
          width: 25vw;
        }
      }
    }
  }
  .header-menu {
    padding: 30px 15px 0 0;
    display: none;
    @include breakpoint(m) {
      display: block;
    }
    ul {
      margin: 0;
      padding: 0;
      li {
        list-style-type: none;
        a.menubutton {
          font-size: 22px;
          line-height: 52px;
          color: #fff;
          text-transform: uppercase;
          font-weight: bold;
          letter-spacing: 8.73px;
          text-decoration: none;
          transition: color 300ms ease;
          &:hover {
            color: $red;
            transition: color 300ms ease;
          }
        }
      }
    }
  }
  .container {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
  }
  h1 {
    color: #ffffff;
    text-align: center;
    line-height: 56px;
    font-size: 50px;
    font-weight: bold;
    @include breakpoint(m) {
      line-height: 5vw;
      font-size: 5vw;
    }
    @include breakpoint(l) {
      line-height: 4vw;
      font-size: 4vw;
    }
  }
  h2 {
    font-size: 26px;
    color: #ffffff;
    letter-spacing: 0;
    text-align: center;
    line-height: 44px;
    font-weight: 300;
    @include breakpoint(m) {
      line-height: 54px;
      font-size: 36px;
    }
  }
  .button-container {
    display: flex;
    padding-top: 40px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @include breakpoint(m) {
      flex-direction: row;
    }
    .or {
      font-size: 30px;
      font-style: italic;
      color: #e5e1ee;
      text-align: center;
      padding-bottom: 10px;
      font-size: 0;
      @include breakpoint(m) {
        padding: 0 40px;
        font-size: 30px;
      }
    }
  }
  .container {
    padding-bottom: 40px;
  }
}

/*Basic Grid Styles*/
