// ==========================================================================
// Base – Global Resets
// ==========================================================================

// Border Box
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

// HTML Font / Text Size Resets
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
html,
button,
input,
select,
textarea {
  font-family: inherit;
}

// HTML5 Elements
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

// Remove Default Margins
body,
form,
fieldset,
legend,
input,
select,
textarea,
button {
  margin: 0;
}

// Audio and Video Elements
audio:not([controls]) {
  display: none;
  height: 0;
}
audio,
canvas,
progress,
video {
  display: inline-block;
}
progress {
  vertical-align: baseline;
}

// Hidden Elements
[hidden],
template {
  display: none;
}

// Images
img {
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
