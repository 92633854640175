// ==========================================================================
// Base – Forms
// ==========================================================================

// Form, Fieldset and Legend
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

// All Form Elements
label,
button,
input,
optgroup,
select,
textarea {
  border-radius: 0;
  color: $base-input-color;
  font: inherit;
  margin: 0;
}

// Mixed Input Fields
[type="text"],
[type="email"],
[type="password"],
[type="tel"],
[type="number"],
[type="date"] {
  padding: 10px 10px;
  background-color: $base-input-background-color;
  border: 0;
  font-size: 18px;
  color: #2d3047;
  letter-spacing: 0;
  line-height: 31.5px;
  -moz-appearance: textfield;
  border-radius: 0;
  width: 100%;
  @include breakpoint(m) {
    padding: 25px 29px;
    font-weight: bold;
    font-size: 22px;
  }
  &:focus {
    background-color: $base-input-background-focus-color;
    border-color: $base-input-border-focus-color;
    outline: 0;
  }
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="date"]::-webkit-inner-spin-button {
  display: none;
}
[type="checkbox"],
[type="radio"] {
  border-radius: 0;
  box-sizing: border-box;
  padding: 0;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
}

// Text Area
textarea {
  height: 200px;
  border-radius: 0;
  padding: 10px;
  background-color: $base-input-background-color;
  border: 0;
  overflow: auto;
  border-radius: 0;
  width: 100%;
  font-size: 18px;
  color: #2d3047;
  letter-spacing: 0;
  line-height: 31.5px;
  &:focus {
    background-color: $base-input-background-focus-color;
    border-color: $base-input-border-focus-color;
    outline: 0;
  }
  @include breakpoint(m) {
    padding: 25px 29px;
    font-weight: bold;
    font-size: 22px;
    height: 300px;
  }
}

.checker {
  .wpcf7-list-item-label {
    display: none;
  }
  .inline {
    font-size: 14px;
    padding-left: 10px;
  }
}

// Select
select {
  border-radius: 0;
  height: 50px;
  text-transform: none;
  padding: 14px 14px;
  background-color: $base-input-background-color;
  border: 1px solid $base-input-border-color;
  width: 100%;
  &:focus {
    background-color: $base-input-background-focus-color;
    border-color: $base-input-border-focus-color;
    outline: 0;
  }
}
optgroup {
  font-weight: 700;
}

// Buttons
button {
  border-radius: 0;
  overflow: visible;
  text-transform: none;
  cursor: pointer;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  border-radius: 0;
}

.wpcf7-submit {
  background: transparent;
  border: 0;
  color: white;
  width: 100%;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

button[disabled],
html input[disabled] {
  cursor: not-allowed;
}

// Placeholder Colour
input::-webkit-input-placeholder {
  color: $base-input-placeholder-color;
}
input:-moz-placeholder {
  color: $base-input-placeholder-color;
}
input::-moz-placeholder {
  color: $base-input-placeholder-color;
}
input:-ms-input-placeholder {
  color: $base-input-placeholder-color;
}

body div.wpcf7-validation-errors {
  background: #ee4751;
  color: white;
  text-align: center;
  padding: 10px;
  font-weight: bold;
  border: 0;
  margin-left: 0px;
  width: 100%;
}

.screen-reader-response[role="alert"] {
  display: none;
}
[role="alert"] {
  display: block;
  padding: 10px;
  margin: 10px 20px 20px;
  background: #ee4751;
  color: white;
  text-align: center;
  font-weight: bold;
  clear: both;
  &.wpcf7-not-valid-tip {
    margin: 0;
    text-align: left;
    font-weight: normal;
    background: transparent;
    padding: 5px 0 0 0;
  }
}

body #download-form div.wpcf7-validation-errors {
  background: #ee4751;
  color: white;
  text-align: center;
  padding: 10px;
  font-weight: bold;
  border: 0;
  margin-left: 15px;
  width: auto;
  margin-right: 15px;
  margin-bottom: 20px;
}

body .wpcf7-not-valid-tip {
  color: white;
}
input::placeholder {
  color: #2a2d34;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #2a2d34;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #2a2d34;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #2a2d34;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #2a2d34;
}

.send-button {
  width: 100%;
  background: transparent;
  border: solid 4px $red;
  padding: 15px;
  cursor: pointer;
  font-weight: bold;
  font-size: 24px;
  color: $red;
  text-align: center;
  transition: background 300ms ease, color 300ms ease;
  &:hover {
    background: $red;
    transition: background 300ms ease, color 300ms ease;
    color: #fff;
    border-color: #fff;
  }
}

.error-message {
  color: $red;
  padding-left: 5px;
  padding-top: 5px;
  font-style: italic;
}

.thankyou_message {
  text-align: center;
  p.maintext {
    font-weight: bold;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    color: #2d3047;
    line-height: 57px;
    text-transform: uppercase;
  }
  p.smaller-text {
    color: #6761fc;
    font-weight: bold;
  }
}
