#recent-jobs {
  margin-top: 0;
  background: #fff;
  padding: 30px 0 30px 0;
  text-align: center;
  @include breakpoint(m) {
    padding: 80px 0 80px 0;
  }
  @include breakpoint(l) {
    //margin-top: 0;
  }
  p {
    margin: 0;
  }
  .title {
    font-size: 42px;
    line-height: 52px;
    font-weight: bold;
    margin-bottom: 30px;
  }
  .description {
    font-size: 18px;
    line-height: 27px;
  }
}
