// Footer
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

body #wrapper {
  background: white;
  color: #282828;
  display: flex; //forces footer down
  flex-direction: column; //forces footer down
  min-height: 100vh; //forces footer down
}

#footer {
  margin-top: auto; //forces footer down
  background: grey;
}

.container {
  &.hello-section {
    margin-bottom: 40px;
    display: block;
    @include breakpoint(m) {
      margin-bottom: 80px;
    }
    p {
      color: #eaecf1;
      letter-spacing: 0;
      line-height: 65px;
      font-size: 100px;
      padding-left: 0;
      font-weight: bold;
      padding-top: 40px;
      text-align: center;
      @include breakpoint(m) {
        font-size: 200px;
        padding-left: 40px;
        line-height: 215px;
        text-align: left;
        padding-top: 20px;
      }
      @include breakpoint(xl) {
        font-size: 13vw;
        line-height: 13vw;
        padding-left: 13vw;
        text-align: left;
        padding-top: 50px;
      }
    }
    h3 {
      font-weight: bold;
      font-size: 38px;
      line-height: 52px;
      color: #2d3047;
      letter-spacing: 0;
      text-align: center;
      @include breakpoint(m) {
        font-size: 48px;
        line-height: 60px;
      }
      @include breakpoint(l) {
        font-size: 2.8vw;
        line-height: 2.8vw;
      }
      img {
        padding: 0 15px;
      }
    }
  }
}
