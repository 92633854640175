#testimonials {
  padding-top: 30px;
  padding-bottom: 30px;
  background: #eaecf1;

  @include breakpoint(m) {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  @include breakpoint(l) {
    padding: 80px 15px;
  }
  .testimonial {
    padding: 20px;
    .inner {
      background: #fff;
      padding: 60px 25px;
      box-shadow: 0 0 44px 0 rgba(0, 0, 0, 0.17);
      .custom-links {
        text-align: center;
        text-decoration: none;
        display: block;
        font-weight: 900;
        color: $red;
        transition: color 300ms ease;
        padding-bottom: 15px;
        text-transform: uppercase;
        &:hover {
          color: lighten($red, 10%);
          transition: color 300ms ease;
        }
      }
      .description {
        color: #2e2e2e;
        //font-style: italic;
        font-weight: 300;
        text-align: center;
      }
      .person {
        border-radius: 100%;
        background-position: center center;
        background-size: cover;
        height: 158px;
        width: 158px;
        display: inline-block;
        margin-bottom: 30px;
      }
    }
  }
}
