#skill-used {
  background: #2d3047;
  padding-bottom: 15px;
  overflow: hidden;
  padding-bottom: 50px;
  padding-top: 50px;
  @include breakpoint(m) {
    padding: 80px 15px;
  }
  .leftside {
    color: #fff;
    padding-bottom: 30px;
    @include breakpoint(m) {
      padding-bottom: 20px;
    }
    .title {
      font-size: 42px;
      line-height: 52px;
      font-weight: bold;
      color: #fff;
      margin-bottom: 30px;
    }
  }
  .container {
    display: flex;
    flex-direction: column;
    padding: 0;
    @include breakpoint(m) {
      flex-wrap: wrap;
      max-width: 1600px;
      flex-direction: row;
      align-items: center;
    }
  }
  .skills-wrapper {
    justify-content: center;
    img {
      padding: 0 10px 20px;
      cursor: pointer;
    }
  }
  img {
    max-width: 100%;
    height: auto;
  }
}
