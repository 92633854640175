// Website Variables and Colours

$grey: #3c3c3b;

.white-text {
  color: white;
}

$blue: #6760fc;
$purple: #6747c7;
$white: #dde7e7;

//oring
$red: #ff4547;
$darkblue: #2706a0;

// //option 1
// $red: #89d8bc;
// $darkblue: #273773;

// // //option2
// $red: #eb2088;
// $darkblue: #080a51;

// // //option3
// $red: #fbcc00;
// $darkblue: #4484cb;
