.about-me {
  padding-bottom: 0;
  @include breakpoint(m) {
    padding-bottom: 0px;
  }
  @include breakpoint(l) {
    padding-bottom: 80px;
  }
  @media only screen and (min-width: 1400px) {
    padding-bottom: 260px;
  }
  .container {
    display: flex;
    flex-direction: column;
    padding: 0;
    @include breakpoint(m) {
      flex-direction: row;
    }
    @include breakpoint(l) {
      display: block;
      padding: 0 50px;
    }
  }
  .leftside {
    background: $darkblue;
    padding: 30px 15px 40px 15px;
    position: relative;
    z-index: 2;
    order: 2;
    text-align: center;
    @include breakpoint(m) {
      top: 0;
      text-align: left;
      margin-right: 0;
      order: unset;
      padding: 30px 30px 40px 30px;
      max-width: calc(70% - 60px);
    }
    @include breakpoint(m) {
      max-width: calc(70% - 30px);
    }
    @media only screen and (min-width: 1400px) {
      top: 160px;
      max-width: calc(100% - 670px);
    }
    h3 {
      font-weight: bold;
      font-size: 48px;
      color: #ffffff;
      letter-spacing: 0;
      line-height: 72px;
      padding-left: 20px;
    }
    .description {
      font-size: 20px;
      color: #ffffff;
      letter-spacing: 0.5px;
      line-height: 32px;
    }
  }
  .rightside {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 30%;

    @include breakpoint(m) {
      width: calc(30% + 30px);
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }
    @include breakpoint(l) {
      right: 50px;
    }
    @media only screen and (min-width: 1400px) {
      width: auto;
      max-width: 100%;
      height: auto;
    }
    img {
      display: block;
      max-width: 100%;
      height: auto;
      @include breakpoint(m) {
        display: none;
      }
      @media only screen and (min-width: 1400px) {
        display: block;
        box-shadow: 0px 2px 70px rgba(0, 0, 0, 0.29);
      }
    }
  }
}
