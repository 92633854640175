.clear:before,
.clear:after {
  content: " ";
  display: table;
}
.clear:after {
  clear: both;
}
.row:before,
.row:after {
  content: "";
  display: table;
}
.row:after {
  clear: both;
}

// Rows
.row {
  position: relative;
  margin-left: -$grid-gutter;
  margin-right: -$grid-gutter;
}
// Medium Device Row
@include breakpoint(m) {
  .row-m {
    position: relative;
    margin-left: -$grid-gutter-m;
    margin-right: -$grid-gutter-m;
    &:before,
    &:after {
      content: "";
      display: table;
    }
    &:after {
      clear: both;
    }
  }
  .clear-m:before,
  .clear-m:after {
    content: "";
    display: table;
  }
  .clear-m:after {
    clear: both;
  }
}
// Large Device Row
@include breakpoint(l) {
  .row-l {
    position: relative;
    margin-left: -$grid-gutter-l;
    margin-right: -$grid-gutter-l;
    &:before,
    &:after {
      content: "";
      display: table;
    }
    &:after {
      clear: both;
    }
  }
  .clear-l:before,
  .clear-l:after {
    content: "";
    display: table;
  }
  .clear-l:after {
    clear: both;
  }
}
// Extra Large Device Row
@include breakpoint(xl) {
  .row-xl {
    position: relative;
    margin-left: -$grid-gutter-xl;
    margin-right: -$grid-gutter-xl;
    &:before,
    &:after {
      content: "";
      display: table;
    }
    &:after {
      clear: both;
    }
  }
  .clear-xl:before,
  .clear-xl:after {
    content: "";
    display: table;
  }
  .clear-xl:after {
    clear: both;
  }
}

// Container
.container {
  padding-left: $grid-gutter;
  padding-right: $grid-gutter;
  margin-left: auto;
  margin-right: auto;
}

// Mobile-first Grid Columns - Global Rules
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  padding-left: $grid-gutter;
  padding-right: $grid-gutter;
  position: relative;
  float: left;
}

// Mobile-first Grid
$columns: 12;
@for $i from 1 through $columns {
  .col-#{$i} {
    width: ($i / $columns) * 100%;
  }
}

@include breakpoint(m) {
  .col-1-m,
  .col-2-m,
  .col-3-m,
  .col-4-m,
  .col-5-m,
  .col-6-m,
  .col-7-m,
  .col-8-m,
  .col-9-m,
  .col-10-m,
  .col-11-m,
  .col-12-m {
    padding-left: $grid-gutter-m;
    padding-right: $grid-gutter-m;
    position: relative;
    float: left;
  }

  // Medium Device Grid
  $columns: 12;
  @for $i from 1 through $columns {
    .col-#{$i}-m {
      width: ($i / $columns) * 100%;
    }
  }
}

// Large Device Grid
@include breakpoint(l) {
  // Large Device Grid Columns - Global Rules
  .col-1-l,
  .col-2-l,
  .col-3-l,
  .col-4-l,
  .col-5-l,
  .col-6-l,
  .col-7-l,
  .col-8-l,
  .col-9-l,
  .col-10-l,
  .col-11-l,
  .col-12-l {
    padding-left: $grid-gutter-l;
    padding-right: $grid-gutter-l;
    position: relative;
    float: left;
  }

  // Large Device Grid
  $columns: 12;
  @for $i from 1 through $columns {
    .col-#{$i}-l {
      width: ($i / $columns) * 100%;
    }
  }
}

// Extra Large Device Grid
@include breakpoint(xl) {
  // Extra Large Device Grid Columns - Global Rules
  .col-1-xl,
  .col-2-xl,
  .col-3-xl,
  .col-4-xl,
  .col-5-xl,
  .col-6-xl,
  .col-7-xl,
  .col-8-xl,
  .col-9-xl,
  .col-10-xl,
  .col-11-xl,
  .col-12-xl {
    padding-left: $grid-gutter-xl;
    padding-right: $grid-gutter-xl;
    position: relative;
    float: left;
  }

  // Extra Large Device Grid
  $columns: 12;
  @for $i from 1 through $columns {
    .col-#{$i}-xl {
      width: ($i / $columns) * 100%;
    }
  }
}
